import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { isObject, merge, snakeCase } from 'lodash';

import { omit, pick } from 'util/objectMethods';
import { trackUxEvent } from 'util/tracking';
import { EVENT_ACTIONS, TRACK_ACTION_TYPES } from 'util/tracking_constants';

const RootContext = React.createContext({});
const PartitionContext = React.createContext({});

const ROOT_ATTRIBUTES = [
  'productArea',
  'eventCategory',
  'eventAction',
  'actionType',
];

const preparePayload = attributes =>
  Object.assign(pick(attributes, ROOT_ATTRIBUTES), {
    properties: omit(attributes, ROOT_ATTRIBUTES),
  });

export const withUxRoot = initialContext => WrappedComponent => props => {
  const value = useMemo(() => Object.assign({}, initialContext), []);
  const ref = useRef(value);

  return (
    <RootContext.Provider value={ref}>
      <WrappedComponent {...props} />
    </RootContext.Provider>
  );
};

// TODO: this should be a temporary alias that we remove after
// this has been merged.
export const withUxEvents = withUxRoot;

export const withUxPartition =
  partitionContext => WrappedComponent => props => {
    const rootContext = useContext(RootContext)?.current || {};
    const parentPartitionContext = useContext(PartitionContext)?.current || {};
    const value = useMemo(
      () =>
        Object.assign(
          {},
          rootContext,
          parentPartitionContext,
          partitionContext
        ),
      [parentPartitionContext, rootContext]
    );
    const ref = useRef(value);

    return (
      <PartitionContext.Provider value={ref}>
        <WrappedComponent {...props} />
      </PartitionContext.Provider>
    );
  };

export const useTrackUx = (componentContext = null) => {
  const rootContext = useContext(RootContext);
  const partitionContext = useContext(PartitionContext);

  return useCallback(
    (eventAction, actionType, eventContext = {}) => {
      const payload = preparePayload(
        Object.assign(
          {},
          rootContext?.current || {},
          partitionContext?.current || {},
          componentContext || {},
          eventContext,
          {
            eventAction,
            actionType,
          }
        )
      );

      /* If all required UX event attributes are not present, the event will not fire */
      if (ROOT_ATTRIBUTES.every(attribute => !!payload[attribute])) {
        trackUxEvent(payload);
      }
    },
    [componentContext, partitionContext, rootContext]
  );
};

export const useExtendUx = () => {
  const context = useContext(RootContext)?.current || {};
  return useCallback(
    newContext => {
      Object.assign(context, newContext);
    },
    [context]
  );
};

export const useExtendUxPartition = () => {
  const context = useContext(PartitionContext)?.current || {};
  return useCallback(
    newContext => {
      Object.assign(context, newContext);
    },
    [context]
  );
};

export const useUxContext = () => {
  const rootContext = useContext(RootContext);
  const partitionContext = useContext(PartitionContext);

  return useCallback(
    () =>
      Object.assign(
        {},
        rootContext?.current || {},
        partitionContext?.current || {}
      ),
    [partitionContext, rootContext]
  );
};

export const useTrackUxHandler = (
  eventAction,
  actionType,
  eventContext,
  options = {}
) => {
  const trackUx = useTrackUx();
  const valueAttribute = options.valueAttribute;

  return useCallback(
    event => {
      let context = eventContext;
      if (valueAttribute) {
        // Account for whether this is a standard event object or
        // the actual value -- if the former, extract value from
        // event.target.
        const value = isObject(event) ? event.target.value : event;
        context = merge({}, eventContext, { [valueAttribute]: value });
      }
      trackUx(eventAction, actionType, context);
    },
    [trackUx, eventAction, actionType, valueAttribute, eventContext]
  );
};

export const useTrackFieldError = (field, touched, errors, serverError) => {
  const trackUx = useTrackUx();
  const clientTouched = touched[field];
  const clientError = errors[field];

  useEffect(
    () => {
      if (clientTouched && clientError) {
        trackUx(EVENT_ACTIONS.VALIDATION_ERROR_SHOW, TRACK_ACTION_TYPES.VIEW, {
          error_field: snakeCase(field),
          error_message: clientError,
          error_source: 'client',
          error_type: 'validation',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field, clientTouched, clientError]
  );

  useEffect(
    () => {
      if (serverError) {
        trackUx(EVENT_ACTIONS.VALIDATION_ERROR_SHOW, TRACK_ACTION_TYPES.VIEW, {
          error_field: snakeCase(field),
          error_message: serverError,
          error_source: 'server',
          error_type: 'validation',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field, serverError]
  );
};

export const withUxTracking = context => WrappedComponent => props => {
  const trackUx = useTrackUx(context);
  const extendUx = useExtendUx();

  return <WrappedComponent trackUx={trackUx} extendUx={extendUx} {...props} />;
};

export const useTrackUxOnMount = (eventAction, actionType, context) => {
  const trackUx = useTrackUx();

  useEffect(() => {
    trackUx(eventAction, actionType, context);
  }, [eventAction, actionType, context, trackUx]);
};
