import './AvatarMenuNavItem.scss';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import { omit } from 'lodash';
import PropTypes from 'prop-types';

import { openGetMobileAppModal } from 'actions/modals';

import { getNewReferralNavTitleForNewCompanyEnabled } from 'selectors/session';
import * as sessionSelectors from 'selectors/session';

import { MANAGE_PLANS_PATH } from 'features/managePlan/constants';
import { SESSION_STORAGE_KEYS } from 'features/onboarding/constants';
import { hidePayrollCountdownBannerLocalStorageKey } from 'features/payroll/constants';

import Avatar from 'components/Avatar';
import Box from 'components/Box';
import Icon from 'components/Icon';
import { Desktop } from 'components/MediaQuery';
import Popper from 'components/Popper';
import Text from 'components/Text';

import cxHelpers from 'util/className';
import { trackEvent, trackUxEvent } from 'util/tracking';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
  TRACK_ACTION_TYPES,
} from 'util/tracking_constants';

import withCloseMobileNav from '../../hocs/withCloseMobileNav';
import withNavItemData from '../../hocs/withNavItemData';
import NavItem from '../NavItem';

@cxHelpers('AvatarMenuNavItem')
export class AvatarMenuNavItem extends PureComponent {
  static propTypes = {
    profileUrl: PropTypes.string.isRequired,
    referralsUrl: PropTypes.string.isRequired,
    signOutUrl: PropTypes.string.isRequired,
    showManagerLinks: PropTypes.bool,
    currentUserHasNotUsedApp: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    avatarUrl: PropTypes.string.isRequired,
    onCloseMobileNav: PropTypes.func.isRequired,
    signOutOnly: PropTypes.bool,
    openGetMobileAppModal: PropTypes.func.isRequired,
    currentLocationTierName: PropTypes.string,
    open: PropTypes.bool,
    showNewReferralTitle: PropTypes.bool,
  };

  state = {
    open: this.props.open || false,
    anchorEl: null,
  };

  receiveAnchorEl = element => this.setState({ anchorEl: element });

  handleTouchTap = event => {
    // This prevents ghost click.
    event.preventDefault();

    trackUxEvent({
      productArea: PRODUCT_AREAS.NAVIGATION,
      eventCategory: EVENT_CATEGORIES.NAV,
      eventAction: EVENT_ACTIONS.PROFILE_CLICKED,
    });

    this.setState({ open: !this.state.open });
  };

  handleRequestClose = event => {
    if (event) {
      event.preventDefault();
    }

    this.setState({ open: false });
    this.props.onCloseMobileNav();
  };

  handleClickMyWeek = () => {
    this.handleRequestClose();
    window.Homebase.RailsReactBridge.navigateToReactView(this.props.profileUrl);
  };

  handleClickUserSettings = () => {
    this.handleRequestClose();
    window.Homebase.RailsReactBridge.navigateToReactView(
      '/settings/your_account/profile'
    );
  };

  handleClickReferrals = () => {
    this.handleRequestClose();
    window.Homebase.RailsReactBridge.navigateToReactView(
      this.props.referralsUrl
    );
  };

  handleClickTiers = () => {
    trackEvent('Upgrades - Clicked Manage Plan Btn');
    trackUxEvent({
      eventAction: EVENT_ACTIONS.MANAGE_PLAN_CLICKED,
      eventCategory: EVENT_CATEGORIES.NAV,
      productArea: PRODUCT_AREAS.NAVIGATION,
    });
    this.handleRequestClose();
    window.Homebase.RailsReactBridge.navigateToReactView(MANAGE_PLANS_PATH);
  };

  handleClickBilling = () => {
    this.handleRequestClose();

    window.Homebase.RailsReactBridge.navigateToReactView(
      '/settings/location/billing'
    );
  };

  handleGetMobileApp = () => {
    trackUxEvent({
      productArea: PRODUCT_AREAS.NAVIGATION,
      eventCategory: EVENT_CATEGORIES.NAV_PROFILE_DROPDOWN,
      eventAction: EVENT_ACTIONS.GET_APP_CLICKED,
      actionType: TRACK_ACTION_TYPES.CLICK,
    });
    this.handleRequestClose();
    this.props.openGetMobileAppModal();
  };

  handleSignOutClick = () => {
    window.localStorage.removeItem(hidePayrollCountdownBannerLocalStorageKey);
    window.sessionStorage.removeItem(
      SESSION_STORAGE_KEYS.TIMESHEETS_GUIDED_SETUP_MAYBE_LATER
    );
    this.handleRequestClose();
  };

  renderMenu(mobile) {
    const {
      signOutUrl,
      showManagerLinks,
      currentUserHasNotUsedApp,
      signOutOnly,
    } = this.props;

    return (
      <MenuList className={this.cxEl('menu')}>
        {!signOutOnly && (
          <a
            onClick={this.handleClickMyWeek}
            className={this.cxEl('menu-item', { mobile }, 'heap--navbar-20')}
          >
            <MenuItem className={this.cxEl('menu-item-container', { mobile })}>
              <Box h={mobile ? 30 : 35} vcenter>
                <Text i18n="nav_links.dashboard.user" fw700 />
              </Box>
            </MenuItem>
          </a>
        )}

        {!signOutOnly && (
          <a
            onClick={this.handleClickUserSettings}
            className={this.cxEl('menu-item', { mobile }, 'heap--navbar-21')}
          >
            <MenuItem className={this.cxEl('menu-item-container', { mobile })}>
              <Box h={mobile ? 30 : 35} vcenter>
                <Text i18n="nav_links.settings.user" fw700 />
              </Box>
            </MenuItem>
          </a>
        )}

        {!signOutOnly && showManagerLinks && (
          <a
            onClick={this.handleClickTiers}
            className={this.cxEl('menu-item', { mobile }, 'heap--navbar-22')}
          >
            <MenuItem className={this.cxEl('menu-item-container', { mobile })}>
              <Box h={mobile ? 30 : 35} vcenter>
                <Text i18n="nav_links.tiers.top_level" fw700 />
              </Box>
            </MenuItem>
          </a>
        )}

        {!signOutOnly && showManagerLinks && (
          <a
            onClick={this.handleClickBilling}
            className={this.cxEl('menu-item', { mobile }, 'heap--navbar-23')}
          >
            <MenuItem className={this.cxEl('menu-item-container', { mobile })}>
              <Box h={mobile ? 30 : 35} vcenter>
                <Text i18n="nav_links.billing" fw700 />
              </Box>
            </MenuItem>
          </a>
        )}

        {!signOutOnly && (
          <a
            onClick={this.handleClickReferrals}
            className={this.cxEl('menu-item', { mobile }, 'heap--navbar-24')}
          >
            <MenuItem className={this.cxEl('menu-item-container', { mobile })}>
              <Box h={mobile ? 30 : 35} vcenter>
                <Text
                  i18n={`nav_links.${
                    this.props.showNewReferralTitle
                      ? 'user_referrals_experiment'
                      : 'user_referrals'
                  }`}
                  fw700
                />
              </Box>
            </MenuItem>
          </a>
        )}

        {!signOutOnly && currentUserHasNotUsedApp && (
          <a
            onClick={this.handleGetMobileApp}
            className={this.cxEl('menu-item', { mobile }, 'heap--navbar-27')}
          >
            <MenuItem className={this.cxEl('menu-item-container', { mobile })}>
              <Box h={mobile ? 30 : 35} vcenter>
                <Text i18n="nav_links.get_mobile_app" theme="link" fw700 />
              </Box>
            </MenuItem>
          </a>
        )}

        <a
          onClick={this.handleSignOutClick}
          href={signOutUrl}
          data-method="delete"
          rel="nofollow"
          className={this.cxEl('menu-item', { mobile }, 'heap--navbar-25')}
        >
          <MenuItem className={this.cxEl('menu-item-container', { mobile })}>
            <Box h={mobile ? 30 : 35} vcenter>
              <Text i18n="nav_links.devise.sign_out" fw700 />
            </Box>
          </MenuItem>
        </a>
      </MenuList>
    );
  }

  renderDesktop() {
    return (
      <Box>
        <NavItem
          className="heap--navbar-17"
          whiteText
          onClick={this.handleTouchTap}
          persistMobileNavOnClick
          data-testid="navItemIcon"
        >
          <Box vcenter>
            <Avatar
              size={24}
              border={this.props.newHeader ? null : 'violetLight'}
              url={this.props.avatarUrl}
            />
            <Icon type={this.state.open ? 'caretUp' : 'caretDown'} />
          </Box>
        </NavItem>
        <Popper
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          placement="bottom-end"
        >
          <Paper>
            <ClickAwayListener onClickAway={this.handleRequestClose}>
              {this.renderMenu()}
            </ClickAwayListener>
          </Paper>
        </Popper>
      </Box>
    );
  }

  renderMobile() {
    return (
      <NavItem
        className="heap--navbar-17"
        whiteText
        onClick={this.handleTouchTap}
        persistMobileNavOnClick
        vcenter={false}
        h="auto"
        noAnchor
      >
        <Box h={40} vcenter relative>
          <Text fs14 i18n="nav_links.account" />

          <Box absolute top={10} right={0}>
            <Icon
              type={this.state.open ? 'chevronDownSlim' : 'chevronRightSlim'}
            />
          </Box>
        </Box>
        {this.state.open && this.renderMenu(true)}
      </NavItem>
    );
  }

  render() {
    const {
      signOutUrl,
      referralsUrl,
      showManagerLinks,
      currentUserHasNotUsedApp,
      avatarUrl,
      currentLocationTierName,
      newHeader,
      open,
      ...boxProps
    } = omit(
      this.props,
      'onCloseMobileNav',
      'openGetMobileAppModal',
      'dispatch',
      'signOutOnly',
      'fullName',
      'settingsUrl',
      'profileUrl'
    );

    return (
      <Box {...boxProps} className={this.cx()} getRef={this.receiveAnchorEl}>
        <Desktop>
          {desktop =>
            desktop || newHeader ? this.renderDesktop() : this.renderMobile()
          }
        </Desktop>
      </Box>
    );
  }
}

export default withNavItemData('avatarMenu')(
  withCloseMobileNav(
    connect(
      (state, props) => ({
        currentLocationTierName: sessionSelectors.getCurrentLocationTierName(
          state,
          props
        ),
        showNewReferralTitle: getNewReferralNavTitleForNewCompanyEnabled(state),
      }),
      {
        openGetMobileAppModal,
      }
    )(AvatarMenuNavItem)
  )
);
