import './PostTaxDeductionsWidget.scss';

import React, { useCallback, useState } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Text from 'fe-design-base/atoms/Text';
import Button from 'fe-design-base/molecules/Button';
import PropTypes from 'prop-types';

import Box from 'components/Box';
import AddButton from 'components/clickables/AddButton';

import { cxHelpers } from 'util/className';

import PostTaxDeductionForm from './PostTaxDeductionForm';
import PostTaxDeductionsList from './PostTaxDeductionsList';

const { cx, cxEl } = cxHelpers('PostTaxDeductionsWidget');

const PostTaxDeductionsWidget = ({
  user,
  updateUserInfo,
  showFormWithSteps,
}) => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const handleAddDeduction = useCallback(() => {
    setIsFormVisible(true);
  }, []);

  const handleOnClickEdit = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleOnCancel = useCallback(() => {
    setIsEditing(false);
    setIsFormVisible(false);
  }, []);

  const handleOnSaveCompleted = useCallback(() => {
    setIsFormVisible(false);
    setIsEditing(false);
  }, []);

  if (!user.get('show_post_tax_deductions')) return null;

  return (
    <Box className={cx()} mb={32}>
      <Box mb={12}>
        <Text
          variant={showFormWithSteps ? 'heading4' : 'heading3'}
          i18n="team.employee_profile.tax_deductions.post_tax_title"
          color={showFormWithSteps && 'mono900'}
        />
      </Box>
      <Box b={!showFormWithSteps} pt={8} pb={8} row bradius>
        <Box grow={2}>
          <PostTaxDeductionsList
            user={user}
            updateUserInfo={updateUserInfo}
            onClickEdit={handleOnClickEdit}
            onCancel={handleOnCancel}
          />
          {isFormVisible ? (
            <PostTaxDeductionForm
              onCancel={handleOnCancel}
              user={user}
              onSaveCompleted={handleOnSaveCompleted}
              updateUserInfo={updateUserInfo}
              showFormWithSteps={showFormWithSteps}
            />
          ) : (
            !isEditing && (
              <Box className={cxEl('addDeduction')}>
                {showFormWithSteps ? (
                  <Button
                    startIcon="Add"
                    variant="tertiary"
                    onClick={handleAddDeduction}
                  >
                    <Text
                      variant="bodyBold"
                      i18n="team.employee_profile.tax_deductions.add_deduction"
                      color="purple500"
                    />
                  </Button>
                ) : (
                  <AddButton
                    i18n="team.employee_profile.tax_deductions.add_deduction"
                    onClick={handleAddDeduction}
                  />
                )}
              </Box>
            )
          )}
        </Box>
      </Box>
    </Box>
  );
};

PostTaxDeductionsWidget.propTypes = {
  user: ImmutablePropTypes.map.isRequired,
  updateUserInfo: PropTypes.func.isRequired,
};

export default PostTaxDeductionsWidget;
