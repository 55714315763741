import React from 'react';
import Text from 'fe-design-base/atoms/Text';
import Button from 'fe-design-base/molecules/Button';
import PropTypes from 'prop-types';

import Box from 'components/Box';

import { cxHelpers } from 'util/className';

import { formatContributionValue, getBenefitType } from './utils.js';

const { cx, cxEl } = cxHelpers('Benefit');

import { moment } from 'util/dateTime';

const Benefit = ({ benefit, mobile, onClickEdit }) => (
  <Box pl={24} pt={24} pr={24} bb className={cx()}>
    <Box row={!mobile} mb={mobile ? 0 : 32}>
      <Box grow basis={0}>
        <Box mb12>
          <Text
            variant="bodyBold"
            lh={1.25}
            i18n="team.employee_profile.tax_deductions.type_label"
          />
        </Box>
        <Box>
          <Text variant="body" lh={1.25}>
            {getBenefitType(benefit).label || '--'}
          </Text>
        </Box>
      </Box>

      <Box grow={2} basis={0}>
        <Box mb12>
          <Text
            variant="bodyBold"
            lh={1.25}
            i18n="team.employee_profile.tax_deductions.name_label"
          />
        </Box>
        <Box>
          <Text variant="body" lh={1.25}>
            {benefit.get('benefit_name') || '--'}
          </Text>
        </Box>
      </Box>

      <Box grow basis={0}>
        <Box tright>
          <Button
            className={cxEl('edit-button')}
            onClick={onClickEdit}
            variant="secondary"
            size="medium"
            startIcon="Edit"
          >
            <Text i18n="actions.edit" />
          </Button>
        </Box>
      </Box>
    </Box>

    <Box mb12 row>
      <Box grow basis={0} row mb={mobile ? 36 : 0}>
        <Box grow basis={0}>
          <Box mb12>
            <Text
              variant="bodyBold"
              lh={1.25}
              i18n="team.employee_profile.tax_deductions.employee_contribution_label"
            />
          </Box>
          <Box>
            <Text variant="body" lh={1.25}>
              {formatContributionValue(benefit, 'employee')}
            </Text>
          </Box>
        </Box>

        <Box grow basis={0}>
          <Box mb12>
            <Text
              variant="bodyBold"
              lh={1.25}
              i18n="team.employee_profile.tax_deductions.company_contribution_label"
            />
          </Box>
          <Box>
            <Text variant="body" lh={1.25}>
              {formatContributionValue(benefit, 'company')}
            </Text>
          </Box>
        </Box>

        <Box grow basis={0}>
          <Box mb12>
            <Text
              variant="bodyBold"
              lh={1.25}
              i18n="team.employee_profile.tax_deductions.start_date_label"
            />
          </Box>
          <Box>
            <Text variant="body" lh={1.25}>
              {benefit.get('start_date')
                ? moment(benefit.get('start_date')).format('MMM D, YYYY')
                : '--'}
            </Text>
          </Box>
        </Box>
        <Box grow basis={0}>
          <Box mb12>
            <Text
              variant="bodyBold"
              lh={1.25}
              i18n="team.employee_profile.tax_deductions.end_date_label"
            />
          </Box>
          <Box>
            <Text variant="body" lh={1.25}>
              {benefit.get('end_date')
                ? moment(benefit.get('end_date')).format('MMM D, YYYY')
                : '--'}
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  </Box>
);

Benefit.propTypes = {
  benefit: PropTypes.object.isRequired,
  mobile: PropTypes.bool,
};

export default Benefit;
