import React from 'react';
import { connect } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Box from 'fe-design-base/atoms/Box';
import { List } from 'immutable';

import { cxHelpers } from 'util/className';
const { cx } = cxHelpers('PaymentMethodModule');

import { getCreditCards } from 'features/biller/implementations/stripe/selectors';
import { fetchCreditCards } from 'features/biller/implementations/stripe/thunks';
import { selectCanViewPaymentMethodModule } from 'features/biller/selectors';

import { PURCHASE_MODULE_OPTIONS } from './constants';
import { PaymentMethodModuleNoPermissionView } from './PaymentMethodModuleNoPermissionView';
import { Price, PurchaseMethodModuleView } from './PurchaseMethodModuleView';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PaymentMethodModuleWrapper = ({ children }: any) => {
  const stripePromise =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.stripeClient && loadStripe(window.stripeClient.publishableKey);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Elements stripe={stripePromise} options={PURCHASE_MODULE_OPTIONS}>
      {children}
    </Elements>
  );
};

export const PurchaseMethodModule = ({
  canViewModule,
  creditCards,
  price,
  onFetchCreditCards,
  onSuccess,
  onLoading,
}: PurchaseMethodModuleProps) => (
  <Box className={cx()}>
    {canViewModule ? (
      <PaymentMethodModuleWrapper>
        <PurchaseMethodModuleView
          creditCards={creditCards}
          onFetchCreditCards={onFetchCreditCards}
          price={price}
          salesTax={0}
          onLoading={onLoading}
          onSuccess={onSuccess}
        />
      </PaymentMethodModuleWrapper>
    ) : (
      <PaymentMethodModuleNoPermissionView />
    )}
  </Box>
);

export type PurchaseMethodModuleProps = {
  canViewModule: boolean;
  price: Price;
  creditCards: List<unknown>;
  onFetchCreditCards: (...args: unknown[]) => void;
  onSuccess: (...args: unknown[]) => void;
  onLoading: (isLoading: boolean) => void;
};

export default connect(
  state => ({
    creditCards: getCreditCards(state),
    canViewModule: selectCanViewPaymentMethodModule(state),
  }),
  {
    onFetchCreditCards: fetchCreditCards,
  }
)(PurchaseMethodModule);
