import { Overrides } from '../../types';

export const formHelperTextOverrides: Overrides = {
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        '&.FDBFormField__form-helper-wrapper': {
          position: 'absolute',
          display: 'flex',
          marginLeft: '0px !important',
          left: 0,
          margin: 0,

          '& .FDBText': {
            lineHeight: '16px',
          },
        },

        '&.FDBRadioGroup__form-helper-wrapper': {
          display: 'flex',
          marginTop: '8px !important',
          marginLeft: '0px !important',
          left: 0,
          margin: 0,
        },

        '&.FDBCheckboxGroup__form-helper-wrapper, &.FDBCheckboxField__form-helper-wrapper':
          {
            display: 'flex',
            marginTop: '8px !important',
            marginLeft: '0px !important',
            left: 0,
            margin: 0,
          },
      },
    },
  },
};
