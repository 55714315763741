import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchJSON } from 'api/fetch';

export const fetchProducts = createAsyncThunk('products/fetchProducts', () =>
  fetchJSON('/tier_products.json')
);

export const fetchFeatures = createAsyncThunk('products/fetchFeatures', () =>
  fetchJSON('/get_features.json')
);

export const fetchTipManagerSubscriptions = createAsyncThunk(
  'tipManager/fetchSubscriptions',
  () =>
    fetchJSON(
      'biller/subscriptions/product_subscriptions.json?product_name=Tip%20Manager'
    )
);
