import React, { useCallback, useState } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect, useDispatch } from 'react-redux';
import * as routes from 'api';
import { deleteJSON, postJSON, putJSON } from 'api/fetch';
import PropTypes from 'prop-types';

import { showConfirmModal } from 'actions/modals';

import { getIsGodModing } from 'selectors/session';

import * as flashNotice from 'util/flashNotice';
import { toI18n } from 'util/i18n';

import EditableSection from '../../EditableSection';

import BenefitFormFields from './BenefitFormFields';
import { BENEFIT_TYPE_MAP, INITIAL_VALUES } from './constants.js';

const BENEFIT_NAME_CHAR_LIMIT = 40;

const BenefitForm = ({
  onCancel,
  onSaveCompleted,
  onDeleteSuccess,
  canDelete,
  user,
  isGmod,
  updateUserInfo,
  benefit,
}) => {
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
  const [isRemoveButtonLoading, setIsRemoveButtonLoading] = useState(false);

  const dispatch = useDispatch();
  const handleOnSave = useCallback(
    values => {
      let benefitApiCall;
      let route;
      let i18nErrorMessage;

      if (values.benefit_id) {
        benefitApiCall = putJSON;
        route = routes.updateBenefitRoute;
        i18nErrorMessage =
          'team.employee_profile.tax_deductions.validations.update_benefit_error';
      } else {
        benefitApiCall = postJSON;
        route = routes.createBenefitRoute;
        i18nErrorMessage =
          'team.employee_profile.tax_deductions.validations.create_benefit_error';
      }

      return benefitApiCall(route(user.get('id')), values).catch(() => {
        flashNotice.error(toI18n(i18nErrorMessage));
      });
    },
    [user]
  );

  const handleValidate = useCallback(values => {
    const errors = {};
    if (!values.benefit_type) {
      errors.benefit_type = toI18n(
        'team.employee_profile.tax_deductions.validations.benefit_type_required'
      );
    }

    if (!values.benefit_name) {
      errors.benefit_name = toI18n(
        'team.employee_profile.tax_deductions.validations.benefit_name.required'
      );
    }

    if (
      values.benefit_name &&
      values.benefit_name.length > BENEFIT_NAME_CHAR_LIMIT
    ) {
      errors.benefit_name = toI18n(
        'team.employee_profile.tax_deductions.validations.benefit_name.max_limit',
        {
          props: {
            limit: BENEFIT_NAME_CHAR_LIMIT,
          },
        }
      );
    }

    if (!values.start_date) {
      errors.start_date = toI18n(
        'team.employee_profile.tax_deductions.validations.start_date_required'
      );
    }

    if (
      parseFloat(values.company_contribution) === 0 &&
      parseFloat(values.employee_contribution) === 0
    ) {
      errors.employee_contribution = toI18n(
        'team.employee_profile.tax_deductions.validations.non_zero_contribution'
      );

      errors.company_contribution = toI18n(
        'team.employee_profile.tax_deductions.validations.non_zero_contribution'
      );
    }

    return errors;
  }, []);

  const handleBenefitRemove = useCallback(() => {
    dispatch(
      showConfirmModal({
        title: toI18n(
          'team.employee_profile.tax_deductions.delete_confirmation_modal.title',
          {
            props: {
              type: BENEFIT_TYPE_MAP[benefit.get('benefit_type')].name,
            },
          }
        ),
        message: toI18n(
          'team.employee_profile.tax_deductions.delete_confirmation_modal.message',
          {
            props: { name: user.get('first_name') },
          }
        ),
        onConfirm: () => {
          setIsRemoveButtonLoading(true);
          setIsSaveButtonDisabled(true);
          deleteJSON(routes.deleteBenefitRoute(user.get('id')), {
            benefit_id: benefit.get('benefit_id'),
          })
            .then(updateUserInfo)
            .then(onDeleteSuccess)
            .catch(() => {
              flashNotice.error(
                toI18n(
                  'team.employee_profile.tax_deductions.validations.delete_benefit_error'
                )
              );
            })
            .finally(() => setIsSaveButtonDisabled(false));
        },
        altType: true,
        submitText: toI18n(
          'team.employee_profile.tax_deductions.delete_confirmation_modal.submit_text'
        ),
        cancelText: toI18n(
          'team.employee_profile.tax_deductions.delete_confirmation_modal.cancel_text'
        ),
      })
    );
  }, [dispatch, benefit, user, updateUserInfo, onDeleteSuccess]);

  return (
    <EditableSection
      initEditMode
      onCancel={onCancel}
      onSave={handleOnSave}
      onSaveCompleted={onSaveCompleted}
      updateUserInfo={updateUserInfo}
      onValidate={handleValidate}
      isSaveButtonDisabled={isSaveButtonDisabled}
      initialValues={benefit ? benefit.toObject() : INITIAL_VALUES}
    >
      {({ editMode, values, initialValues }) =>
        editMode ? (
          <BenefitFormFields
            values={values}
            isGmod={!!isGmod}
            initialValues={initialValues}
            onClickRemove={handleBenefitRemove}
            isRemoveButtonLoading={isRemoveButtonLoading}
            canDelete={canDelete}
          />
        ) : null
      }
    </EditableSection>
  );
};

BenefitForm.propTypes = {
  onCancel: PropTypes.func,
  user: ImmutablePropTypes.map.isRequired,
  updateUserInfo: PropTypes.func.isRequired,
  onSaveCompleted: PropTypes.func.isRequired,
  onDeleteSuccess: PropTypes.func,
  benefit: PropTypes.object,
  canDelete: PropTypes.bool,
};
export default connect(state => ({
  isGmod: getIsGodModing(state),
}))(BenefitForm);
