import React, { useEffect } from 'react';
import MonthCalendar from 'fe-design-base/assets/illustrations/MonthCalendar';
import ShiftsBars from 'fe-design-base/assets/illustrations/ShiftsBars';
import ShiftsCalendar from 'fe-design-base/assets/illustrations/ShiftsCalendar';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';

import { toI18n } from 'util/i18n';
import {
  ELEMENTS,
  EVENT_ACTIONS,
  TRACK_ACTION_TYPES,
} from 'util/tracking_constants';

const LaunchWeekStepHowYouDoIt = ({ onHowYouDoItStep, trackUx }) => {
  const setTimeoutId = onHowYouDoItStep();

  useEffect(() => {
    trackUx(EVENT_ACTIONS.PAGE_VIEWED, TRACK_ACTION_TYPES.VIEW, {
      step_label: ELEMENTS.LAUNCH_WEEK_STEP_LABELS.HOW_YOU_DO_IT,
    });
    return () => {
      clearTimeout(setTimeoutId);
    };
  }, [setTimeoutId, trackUx]);

  return (
    <Box column tleft className="how-you-do-it-content-fade-out">
      <Box className="content-appears-top-bottom">
        <Text variant="heading2" color="mono0">
          {toI18n('growth.launch_week.steps.how_you_do_it.title')}
        </Text>
      </Box>

      <Box column hleft tleft className="how-you-do-it-content-fade-out">
        <Box row vcenter>
          <Box className="how-you-do-it-appears-in-place-increase">
            <ShiftsCalendar w={142} h={142} mr={32} />
          </Box>
          <Box className="how-you-do-it-appears-in-place-fade-in">
            <Text variant="heading1" color="mono0" asHTML>
              {toI18n(
                'growth.launch_week.steps.how_you_do_it.schedule_in_advance'
              )}
            </Text>
          </Box>
        </Box>

        <Box row vcenter>
          <Box
            className="how-you-do-it-appears-in-place-increase"
            style={{ '--element-display-delay-ms': '2300ms' }}
          >
            <ShiftsBars w={142} h={142} mr={32} />
          </Box>
          <Box
            className="how-you-do-it-appears-in-place-fade-in"
            style={{ '--element-display-delay-ms': '2600ms' }}
          >
            <Text variant="heading1" color="mono0" asHTML>
              {toI18n('growth.launch_week.steps.how_you_do_it.trade_shifts')}
            </Text>
          </Box>
        </Box>

        <Box row vcenter>
          <Box
            className="how-you-do-it-appears-in-place-increase"
            style={{ '--element-display-delay-ms': '3400ms' }}
          >
            <MonthCalendar w={142} h={142} mr={32} />
          </Box>
          <Box
            className="how-you-do-it-appears-in-place-fade-in"
            style={{ '--element-display-delay-ms': '3700ms' }}
          >
            <Text variant="heading1" color="mono0" asHTML>
              {toI18n(
                'growth.launch_week.steps.how_you_do_it.honor_availability'
              )}
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LaunchWeekStepHowYouDoIt;
