import React from 'react';
import {
  chalk_play,
  coin_bicycle,
  cukoo_clock,
  donuts_loading_1,
  donuts_loading_2,
  donuts_loading_3,
  flip_clock,
  gears,
  hey_plane,
  hired_name_tag,
  hooray_banner,
  money_clock,
  polaroid_wave,
  shifts_calendar,
  stamp_clock,
  yay_payday,
} from 'fe-design-base/assets/gifs/imageFiles';
import { GifProps } from 'fe-design-base/assets/types';
import Box from 'fe-design-base/atoms/Box';

const buildComponent = (name: string, args: GifProps) => (
  <Box {...args}>
    <img src={name} style={{ width: '100%' }} />
  </Box>
);

export const ChalkPlay = (args: GifProps) => buildComponent(chalk_play, args);
export const CoinBicycle = (args: GifProps) =>
  buildComponent(coin_bicycle, args);
export const CukooClock = (args: GifProps) => buildComponent(cukoo_clock, args);
export const DonutsLoading1 = (args: GifProps) =>
  buildComponent(donuts_loading_1, args);
export const DonutsLoading2 = (args: GifProps) =>
  buildComponent(donuts_loading_2, args);
export const DonutsLoading3 = (args: GifProps) =>
  buildComponent(donuts_loading_3, args);
export const FlipClock = (args: GifProps) => buildComponent(flip_clock, args);
export const Gears = (args: GifProps) => buildComponent(gears, args);
export const HeyPlane = (args: GifProps) => buildComponent(hey_plane, args);
export const HiredNameTag = (args: GifProps) =>
  buildComponent(hired_name_tag, args);
export const HoorayBanner = (args: GifProps) =>
  buildComponent(hooray_banner, args);
export const MoneyClock = (args: GifProps) => buildComponent(money_clock, args);
export const PolaroidWave = (args: GifProps) =>
  buildComponent(polaroid_wave, args);
export const ShiftsCalendar = (args: GifProps) =>
  buildComponent(shifts_calendar, args);
export const StampClock = (args: GifProps) => buildComponent(stamp_clock, args);
export const YayPayday = (args: GifProps) => buildComponent(yay_payday, args);
