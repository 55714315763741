import { includes } from 'lodash';
import { createSelector } from 'reselect';

import { getLocations } from 'selectors/entities';
import {
  getFeaturePermission,
  getHasFeature,
  getPopularRolesForCurrentLocation,
} from 'selectors/session';

import { toI18n } from 'util/i18n';

const buildGetAddTeam = key => state => state.getIn(['addTeam', key]);
export const getSyncingEmployees = buildGetAddTeam('syncingEmployees');
export const getAddEmployeeWarningModalOpen = buildGetAddTeam(
  'addEmployeeWarningModalOpen'
);

export const getSyncLocationOptions = createSelector(
  (_, { source, isPOS }) => ({
    source,
    isPOS,
  }),
  getLocations,
  ({ source, isPOS }, locations) =>
    locations.filter(location => {
      if (
        includes(['quickbooks', 'gusto', 'square', 'adp_run'], source) &&
        !isPOS
      ) {
        return (
          location.has('payroll_provider') &&
          location.getIn(['payroll_provider', 'key']) === source
        );
      }

      return (
        location.has('partner') && location.getIn(['partner', 'key']) === source
      );
    })
);

export const getCurrentLocationIsPOSForSyncPage = state =>
  state.getIn(['session', 'addTeam', 'isPOSForSyncPage']);

export const getCurrentLocationIsPayrollForSyncPage = state =>
  state.getIn(['session', 'addTeam', 'isPayrollForSyncPage']);

export const getCurrentLocationIsIntegratedPOSPartner = state =>
  state.getIn(['session', 'addTeam', 'isIntegratedPOSPartner']);

export const getCurrentLocationIsIntegratedPayrollPartner = state =>
  state.getIn(['session', 'addTeam', 'isIntegratedPayrollPartner']);

export const getOpenedAddTeamModal = state =>
  state.getIn(['session', 'addTeam', 'openedAddTeamModal']);

export const getProperPartnerNames = state =>
  state.getIn(['session', 'addTeam', 'properPartnerNames']);

export const getCompanyRoles = buildGetAddTeam('companyRoles');

export const getCompanyRolesLoading = buildGetAddTeam('companyRolesLoading');

export const getOptionsForRolesSelect = createSelector(
  getCompanyRoles,
  getPopularRolesForCurrentLocation,
  (locationRoles, standardizedRoles) => {
    const options = [
      {
        value: null,
        label: toI18n('add_team.roles_select.no_role'),
      },
    ];

    if (locationRoles.size) {
      options.push({
        label: toI18n('add_team.roles_select.company_roles'),
        value: toI18n('add_team.roles_select.company_roles'),
        disabled: true,
      });

      locationRoles.forEach(role => {
        const value = role.get('name');
        options.push({ value, label: value });
      });
    }

    if (standardizedRoles.size) {
      options.push({
        label: toI18n('add_team.roles_select.standardized_roles'),
        value: toI18n('add_team.roles_select.standardized_roles'),
        disabled: true,
      });

      standardizedRoles.forEach(value => options.push({ value, label: value }));
    }

    return options;
  }
);

export const getCompanyRolesArray = createSelector(
  getCompanyRoles,
  companyRoles => companyRoles.map(role => role.get('name'))
);

export const getAccessLevelOptions = createSelector(
  state => getFeaturePermission(state, { name: 'gm' }),
  gmPermission => {
    const I18N_ACCESS_LEVELS_PATH = 'team.access_levels';
    let options = [
      {
        label: toI18n(`${I18N_ACCESS_LEVELS_PATH}.employee`),
        value: 'Employee',
      },
      {
        label: toI18n(`${I18N_ACCESS_LEVELS_PATH}.manager`),
        value: 'Manager',
      },
    ];

    if (gmPermission) {
      options = [
        ...options,
        {
          label: toI18n(`${I18N_ACCESS_LEVELS_PATH}.general_manager`),
          value: 'General Manager',
        },
      ];
    }

    return options;
  }
);

export const getHasManagersFeatureAccess = state =>
  getHasFeature(state, { feature: 'managers' });

export const getRolesWagesEnabled = state =>
  getHasFeature(state, { feature: 'role_wages' });
export const getSalaryEnabled = state =>
  getHasFeature(state, { feature: 'salary' });

export const getShowAddTeamDrawers = state => {
  // only show add team drawers if values have been set
  // for session.addTeam.
  const addTeam = state.getIn(['session', 'addTeam']);
  return addTeam && addTeam.size > 0;
};
