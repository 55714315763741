import React, { SyntheticEvent, useCallback, useMemo } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MUIAutocomplete, {
  AutocompleteChangeReason,
  AutocompleteCloseReason,
  AutocompleteInputChangeReason,
  createFilterOptions,
} from '@mui/material/Autocomplete';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import withCx, { CxProps } from 'fe-core/util/withCx';
import DropdownItem from 'fe-design-base/atoms/Autocomplete/DropdownItem';
import Box from 'fe-design-base/atoms/Box';
import Icon, { IconName } from 'fe-design-base/atoms/Icon';
import Text from 'fe-design-base/atoms/Text';
import TextInput, { TextInputProps } from 'fe-design-base/atoms/TextInput';
import { ButtonProps } from 'fe-design-base/molecules/Button';
import { typography } from 'fe-design-base/styles/typography';

import { toI18n } from 'util/i18n';

export type Option = {
  [key: string]: any;
} & {
  label: string;
  value: string | number;
  buttonProps?: ButtonProps;
  disabled?: boolean;
};

export interface AutocompleteProps
  extends Omit<TextInputProps, 'onClose' | 'onChange' | 'value'> {
  /* Selected option to display in the input */
  value?: Option | string | null;
  options: string[] | Option[];
  /** Options that will always be fixed to the bottom of the list */
  fixedOptions?: string | Option[];
  /** Invoked when the popup requests to be closed.
   * reason can be: "toggleInput", "escape", "selectOption", "removeOption", "blur". */
  onClose?: (event: SyntheticEvent, reason: AutocompleteCloseReason) => void;
  /** Invoked whenever you select the display options in the popup.
   * reason can be: One of "createOption", "selectOption", "removeOption", "blur" or "clear".
   */
  onChange?: (
    event: SyntheticEvent,
    value: any,
    reason: AutocompleteChangeReason
  ) => void;
  /** Invoked whenever you type in search field.
   * reason: Can be: "input" (user input), "reset" (programmatic change), "clear"
   * */
  onInputChange?: (
    event: SyntheticEvent,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => void;
  /** Control if the input should be blurred when an option is selected */
  blurOnSelect?: 'mouse' | 'touch' | boolean;
  /** A function that disables the built-in filtering
   * If logic is already fetching filtered options, set this to '(x) => x' */
  filterOptions?: (options: Option[], componentState: object) => Option[];
  /** Text to display when there are no options */
  noOptionsText?: string;
  startIcon?: IconName;
  /** Used to determine if the option represents the given value */
  isOptionEqualToValue?: (option: Option, value: any) => boolean;
  /** Show caret at end of input */
  showCaret?: boolean;
  menuMaxHeight?: number;
}
type StyleProp = {
  startIcon?: string;
  menuMaxHeight?: number;
  disabled?: boolean;
};
const useStyles = makeStyles({
  listbox: {
    '&.MuiAutocomplete-listbox': {
      maxHeight: ({ menuMaxHeight }: StyleProp) =>
        `${menuMaxHeight}px` || '40vh',
    },
    '& .MuiAutocomplete-option': {
      fontSize: `${typography.body.fontSize}px`,
      minHeight: '40px',
    },
  },
  root: {
    '&.FDBAutocomplete .MuiOutlinedInput-input.MuiAutocomplete-input': {
      padding: ({ startIcon }) =>
        startIcon ? '7.5px 4px 7.5px 44px' : '7.5px 12px',
    },
  },
});

const Autocomplete = ({
  value = null,
  options,
  fixedOptions,
  disabled,
  onClose,
  onChange,
  onInputChange,
  blurOnSelect,
  noOptionsText = toI18n('fe_design_base.autocomplete.no_options_text'),
  startIcon,
  isOptionEqualToValue,
  showCaret,
  menuMaxHeight,
  cx,
  cxEl,
  ...inputProps
}: AutocompleteProps & CxProps) => {
  const classes = useStyles({ startIcon, menuMaxHeight, disabled });

  const handleRenderInput = useCallback(
    params => <TextInput {...params} disabled={disabled} {...inputProps} />,
    [disabled, inputProps]
  );

  const handleRenderOption = useCallback((props, option, state) => {
    const matches = match(option.label, state.inputValue, {
      insideWords: true,
    });
    const parts = parse(option.label || '', matches);

    return (
      <DropdownItem
        option={option}
        props={props}
        key={option.value}
        parts={parts}
      />
    );
  }, []);

  const normalizeOptions = useCallback(optionArray => {
    if (typeof optionArray[0] === 'string') {
      return optionArray.map((label: string) => ({
        label,
        value: label,
      })) as Option[];
    }
    return optionArray as Option[];
  }, []);

  const normalizedDynamicOptions = useMemo(
    () => normalizeOptions(options),
    [normalizeOptions, options]
  );

  const normalizedFixedOptions = useMemo(
    () => (fixedOptions ? normalizeOptions(fixedOptions) : []),
    [fixedOptions, normalizeOptions]
  );

  const noOptionsTextNode = useMemo(
    () => <Text variant="body">{noOptionsText}</Text>,
    [noOptionsText]
  );

  // Need to manually set an empty state when there are fixedOptions present
  const manualEmptyOption = useCallback(
    (hasOptions: boolean) =>
      !hasOptions && fixedOptions
        ? [
            {
              label: 'noOptionsText',
              value: 'noOptionsText',
              noOptionsTextNode,
            },
          ]
        : [],
    [fixedOptions, noOptionsTextNode]
  );

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    ignoreAccents: true,
  });

  const handleFilterOptions = useCallback(
    (items, optionsState) => {
      const filteredArray = filterOptions(
        normalizedDynamicOptions,
        optionsState
      );

      return [
        ...filteredArray,
        ...manualEmptyOption(!!filteredArray.length),
        ...normalizedFixedOptions,
      ];
    },
    [
      filterOptions,
      manualEmptyOption,
      normalizedDynamicOptions,
      normalizedFixedOptions,
    ]
  );

  const handleGetOptionDisabled = useCallback(
    option => !!option.noOptionsTextNode || !!option.disabled,
    []
  );

  const handleIsOptionEqualToValue = useCallback(
    (opt, val) => {
      if (isOptionEqualToValue) return isOptionEqualToValue(opt, val);

      if (typeof opt === 'string') return opt === val;

      return opt.label === val || opt.label === val?.label;
    },
    [isOptionEqualToValue]
  );

  const handleKeyDown = useCallback(e => {
    if (e.key === 'Tab') {
      e.key = 'Enter';
    }
  }, []);

  return (
    <Box relative>
      <MUIAutocomplete
        value={value || null}
        options={[normalizedDynamicOptions, ...normalizedFixedOptions]}
        className={cx({
          showPopupIcon: showCaret,
        })}
        aria-disabled={disabled}
        classes={classes}
        onClose={onClose}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        onInputChange={onInputChange}
        componentsProps={{
          paper: {
            className: cxEl('paper'),
          },
        }}
        blurOnSelect={blurOnSelect}
        noOptionsText={noOptionsTextNode}
        clearIcon={<Icon iconName="Clear" size="medium" color="primary500" />}
        renderOption={handleRenderOption}
        renderInput={handleRenderInput}
        filterOptions={handleFilterOptions}
        getOptionDisabled={handleGetOptionDisabled}
        isOptionEqualToValue={handleIsOptionEqualToValue}
        selectOnFocus
        readOnly={disabled}
      />
      {startIcon && (
        <Icon
          iconName={startIcon}
          absolute
          top={10}
          left={14}
          color="mono500"
        />
      )}
    </Box>
  );
};

export default withCx<AutocompleteProps>('FDBAutocomplete')(Autocomplete);
