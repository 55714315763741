import { fromJS } from 'immutable';

import { getOptionsForRolesSelect } from 'selectors/addTeam';
import { getLevelOptions, getLocationOptions } from 'selectors/bulkAdd';

import { WAGE_TYPE_OPTIONS } from 'features/addTeam/constants';

import { toI18n } from 'util/i18n';
import {
  isBetween1900AndNowMY,
  isRequired,
  isValidDate,
  isValidEmail,
  isValidPhone,
} from 'util/validators';

export const DEFAULT_COLUMN_WIDTH = 140;

function isPinLengthValid(value, { pinLength }) {
  if (value && value.replace(/[^\d]/g, '').length !== (pinLength || 6)) {
    return toI18n('add_team.bulk_add.table.validations.pin_length', {
      props: { length: pinLength },
    });
  }
}

function multiLocationTooltip(columnKey) {
  const column = toI18n(`add_team.bulk_add.table.headers.${columnKey}`);

  return toI18n('add_team.bulk_add.table.multi_location_tooltip', {
    props: { column },
  });
}

// this constant should contain info about each column, like
// - is it sticky?
// - does it have some validations? if so, which ones?
// - type of the input (date, phone, email, etc)
// - width of the column
export const COLUMNS = {
  counter: {
    width: 40,
    sticky: true,
    type: 'counter',
    leftPosition: 0,
  },
  first_name: {
    sticky: true,
    type: 'text',
    leftPosition: 40,
    validations: [isRequired('First name')],
    paste: true,
  },
  last_name: {
    sticky: true,
    type: 'text',
    leftPosition: 180,
    wideBorder: true,
    paste: true,
  },
  email: {
    type: 'email',
    width: 200,
    validations: [isValidEmail()],
    paste: true,
  },
  phone: {
    type: 'phone',
    validations: [isValidPhone()],
    paste: true,
  },
  role: {
    type: 'roles',
    width: 200,
    options: getOptionsForRolesSelect,
  },
  wage_rate: {
    type: 'currency',
    width: 100,
    paste: true,
  },
  wage_type: {
    type: 'select',
    options: () => WAGE_TYPE_OPTIONS,
    width: 100,
  },
  locations: {
    type: 'multiselect',
    options: getLocationOptions,
    width: 200,
  },
  level: {
    type: 'select',
    options: getLevelOptions,
    tooltip: multiLocationTooltip('level'),
  },
  pin: {
    type: 'text',
    validations: [isRequired('PIN'), isPinLengthValid],
    width: 115,
    tooltip: multiLocationTooltip('pin'),
    paste: true,
  },
  payroll_id: {
    type: 'text',
    paste: true,
    tooltip: multiLocationTooltip('payroll_id'),
  },
  hire_date: {
    type: 'date',
    validations: [isValidDate()(), isBetween1900AndNowMY()],
    width: 110,
    paste: true,
    tooltip: multiLocationTooltip('hire_date'),
  },
  date_of_birth: {
    type: 'date',
    validations: [isValidDate()()],
    width: 110,
    paste: true,
  },
  emergency_contact_name: {
    type: 'text',
    paste: true,
  },
  emergency_contact_phone: {
    type: 'phone',
    validations: [isValidPhone()],
    paste: true,
  },
};

export const BOTTOM_DRAWER_COLUMNS = {
  counter: {
    width: 40,
    sticky: true,
    type: 'counter',
    leftPosition: 0,
  },
  first_name: {
    sticky: true,
    type: 'text',
    leftPosition: 40,
    validations: [isRequired('First name')],
    paste: true,
  },
  last_name: {
    sticky: true,
    type: 'text',
    leftPosition: 180,
    wideBorder: true,
    paste: true,
  },
  locations: {
    type: 'multiselect',
    options: getLocationOptions,
  },
  phone: {
    type: 'phone',
    validations: [isValidPhone()],
    paste: true,
  },
  email: {
    type: 'email',
    validations: [isValidEmail()],
    paste: true,
  },
  role: {
    type: 'roles',
    options: getOptionsForRolesSelect,
  },
  level: {
    type: 'select',
    options: getLevelOptions,
    tooltip: multiLocationTooltip('level'),
  },
};

export const FIELD_OPTIONS_DEFAULTS = fromJS([
  { key: 'email', selected: true, position: 0 },
  { key: 'pin', selected: false, position: 5 },
  { key: 'phone', selected: true, position: 1 },
  { key: 'payroll_id', selected: false, position: 6 },
  { key: 'role', selected: true, position: 2 },
  { key: 'hire_date', selected: false, position: 7 },
  { key: 'wage_rate', selected: true, position: 3 },
  { key: 'date_of_birth', selected: false, position: 8 },
  { key: 'level', selected: true, position: 4 },
  { key: 'emergency_contact', selected: false, position: 9 },
]);

export const BOTTOM_DRAWER_FIELD_OPTIONS_DEFAULTS = fromJS([
  { key: 'email', selected: true, position: 0 },
  { key: 'phone', selected: true, position: 1 },
  { key: 'role', selected: true, position: 2 },
  { key: 'level', selected: true, position: 3 },
]);

export const REQUIRED_COLUMNS = ['counter', 'first_name', 'last_name'];
