import './RecurringEarningsWidget.scss';

import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Text from 'fe-design-base/atoms/Text';
import PropTypes from 'prop-types';

import Box from 'components/Box';

import { cxHelpers } from 'util/className';

import RecurringEarningsList from './RecurringEarningsList';

const { cx } = cxHelpers('RecurringEarningsWidget');

const RecurringEarningsWidget = ({
  user,
  updateUserInfo,
  showFormWithSteps,
}) => {
  if (!user.get('show_recurring_earnings_and_reimbursements')) return null;

  return (
    <Box className={cx()} mb={32}>
      <Box mb={12}>
        <Text
          variant={showFormWithSteps ? 'heading4' : 'heading3'}
          i18n="team.employee_profile.recurring_earnings.title"
          color={showFormWithSteps && 'mono900'}
        />
      </Box>
      <Box b={!showFormWithSteps} pb={8} row bradius>
        <Box grow={2}>
          <RecurringEarningsList
            user={user}
            updateUserInfo={updateUserInfo}
            showFormWithSteps={showFormWithSteps}
          />
        </Box>
      </Box>
    </Box>
  );
};

RecurringEarningsWidget.propTypes = {
  user: ImmutablePropTypes.map.isRequired,
  updateUserInfo: PropTypes.func.isRequired,
};

export default RecurringEarningsWidget;
