import { createSlice } from '@reduxjs/toolkit';
import { fromJS } from 'immutable';

import { aggregateErrors } from '../../util';

import { SLICE_NAME } from './constants';
import {
  addHiringBoost,
  changePaymentMethod,
  changeTier,
  createHiringBoost,
  createSetupIntent,
  fetchAdminCreditCards,
  fetchCreditCards,
  fetchPaymentIntent,
  moduleUpdatePaymentMethod,
  updatePaymentMethod,
} from './thunks';

const slice = createSlice({
  name: SLICE_NAME,
  initialState: fromJS({
    creditCards: fromJS([]),
  }),
  extraReducers: {
    [changeTier.pending]: state => state.set('changeTierPending', true),
    [changeTier.fulfilled]: state => state.set('changeTierPending', false),
    [changeTier.rejected]: state => state.set('changeTierPending', false),
    [fetchCreditCards.pending]: state => state.set('creditCardsError', null),
    [fetchCreditCards.fulfilled]: (state, action) =>
      state.set('creditCards', fromJS(action.payload)),
    [fetchCreditCards.rejected]: (state, action) =>
      state.set('creditCardsError', aggregateErrors(action.payload)),
    [changePaymentMethod.pending]: state =>
      state.merge({
        changePaymentMethodPending: true,
        creditCardsError: null,
      }),
    [changePaymentMethod.fulfilled]: state =>
      state.set('changePaymentMethodPending', false),
    [changePaymentMethod.rejected]: (state, action) =>
      state.merge({
        changePaymentMethodPending: false,
        creditCardsError: aggregateErrors(action.payload),
      }),
    [updatePaymentMethod.pending]: state =>
      state.merge({
        updatePaymentMethodPending: true,
        updatePaymentMethodErrors: null,
      }),
    [updatePaymentMethod.fulfilled]: state =>
      state.set('updatePaymentMethodPending', false),
    [updatePaymentMethod.rejected]: (state, action) =>
      state.merge({
        updatePaymentMethodPending: false,
        updatePaymentMethodErrors: aggregateErrors(action.payload),
      }),
    [moduleUpdatePaymentMethod.pending]: state =>
      state.merge({
        moduleUpdatePaymentMethodPending: true,
        moduleUpdatePaymentMethodErrors: null,
      }),
    [moduleUpdatePaymentMethod.fulfilled]: state =>
      state.set('moduleUpdatePaymentMethodPending', false),
    [moduleUpdatePaymentMethod.rejected]: (state, action) =>
      state.merge({
        moduleUpdatePaymentMethodPending: false,
        moduleUpdatePaymentMethodErrors: aggregateErrors(action.payload),
      }),
    [addHiringBoost.pending]: state =>
      state.merge({
        addHiringBoostPending: true,
        creditCardsError: null,
        addHiringBoostError: null,
        // clearing the error msg
        createHiringBoostErrors: null,
        appliedDiscountError: null,
      }),
    [addHiringBoost.fulfilled]: state =>
      state.set('addHiringBoostPending', false),
    [addHiringBoost.rejected]: (state, action) =>
      state.merge({
        addHiringBoostPending: false,
        addHiringBoostError: aggregateErrors(action.payload),
      }),
    [createHiringBoost.pending]: state =>
      state.merge({
        createHiringBoostPending: true,
        createHiringBoostErrors: null,
      }),
    [createHiringBoost.fulfilled]: state =>
      state.set('createHiringBoostPending', null),
    [createHiringBoost.rejected]: (state, action) =>
      state.merge({
        createHiringBoostPending: false,
        createHiringBoostErrors: aggregateErrors(action.payload),
      }),
    [fetchAdminCreditCards.pending]: state =>
      state.set('creditCardsError', null),
    [fetchAdminCreditCards.fulfilled]: (state, action) =>
      state.set('creditCards', fromJS(action.payload)),
    [fetchAdminCreditCards.rejected]: (state, action) =>
      state.set('creditCardsError', aggregateErrors(action.payload)),
    [createSetupIntent.pending]: state =>
      state.merge({
        setupIntentPending: true,
        setupIntentStatus: null,
        setupIntentSecret: null,
        setupIntentErrors: null,
      }),
    [createSetupIntent.fulfilled]: (state, action) =>
      state.merge({
        setupIntentPending: false,
        setupIntentStatus: action.payload.status,
        setupIntentSecret: action.payload.client_secret,
      }),
    [createSetupIntent.rejected]: (state, action) =>
      state.merge({
        setupIntentPending: false,
        setupIntentErrors: aggregateErrors(action.payload),
      }),
    [fetchPaymentIntent.pending]: state =>
      state.merge({
        paymentIntentPending: true,
        paymentIntentSecret: null,
        paymentIntentErrors: null,
      }),
    [fetchPaymentIntent.fulfilled]: (state, action) =>
      state.merge({
        paymentIntentPending: false,
        paymentIntentSecret: action.payload.client_secret,
      }),
    [fetchPaymentIntent.rejected]: (state, action) =>
      state.merge({
        paymentIntentPending: false,
        paymentIntentErrors: aggregateErrors(action.payload),
      }),
  },
});

export const actions = slice.actions;
export const reducer = slice.reducer;
