import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as routes from 'api';
import { deleteJSON, fetchJSON, postJSON, putJSON } from 'api/fetch';
import { fromJS } from 'immutable';

import { updateRefreshTipOuts, updateRow } from 'actions/timesheets';

import { updateDailyReviewRow } from 'features/timesheets/TimesheetsPage/dailyReview/thunks';

import { DEFAULT_VALIDATION_ERRORS, determineContext } from './utils';

const DEFAULT_STATE = {
  isOpen: false,
  isLoading: false,
  shifts: [],
  currentShiftId: null,
  newTimecard: false,
  newTimecardDate: null,
  hideTipsSection: false,
  changedShiftId: null,
  isSubmitting: false,
  editMode: false,
  startingPath: '',
  validationErrors: DEFAULT_VALIDATION_ERRORS,
  // this is to support old backbone components that need
  // to receive a backbone event after we save a timecard
  backboneEventName: null,
  expandBreaks: null,
};

const INITIAL_STATE = {
  ...DEFAULT_STATE,
  roles: [],
  mandatedBreaks: [],
};

const setEditMode = (state, action) => state.set('editMode', action.payload);
const setValidationErrors = (state, action) =>
  state.set('validationErrors', fromJS(action.payload));

const setChangedShiftId = (state, action) =>
  state.set('changedShiftId', action.payload);

const openTimecardModal = (state, action) =>
  state.merge({
    isOpen: true,
    backboneEventName: action.payload.backboneEventName,
    shifts: action.payload.shifts,
    hideTipsSection: !!action.payload.hideTipsSection,
    currentShiftId:
      action.payload.currentShiftId || action.payload.shifts[0].id,
    startingPath: action.payload.startingPath,
    expandBreaks: action.payload.expandBreaks,
  });

const closeTimecardModal = state => state.merge(DEFAULT_STATE);

const updateShiftData = (state, action) => {
  state = state.set('isLoading', false);
  const index = state
    .get('shifts')
    .findIndex(s => s.get('id') === action.payload.id || !s.get('id'));

  return state
    .set('currentShiftId', action.payload.id)
    .mergeIn(['shifts', index], {
      ...action.payload,
      role_id: action.payload.role_id || null,
      role_name: action.payload.role_name || null,
      loaded: true,
    });
};

export const updateLocationData = (state, action) =>
  state.merge({
    mandatedBreaks: fromJS(action.payload.mandated_breaks),
    roles: fromJS(action.payload.roles),
  });

export const submitTimecardData = createAsyncThunk(
  'timecardModal/submitTimecardData',
  ({ jobId, data }, { dispatch, rejectWithValue }) =>
    putJSON(`/timesheets/timecards/${jobId}`, {
      timecard: data,
    })
      .catch(err => err.response.json().then(body => rejectWithValue(body)))
      .then(response => {
        const isTimecardEligibleForTipPooling =
          response.tip_pooling_timecard_eligible;

        if (isTimecardEligibleForTipPooling) dispatch(updateRefreshTipOuts());

        return response;
      })
);

export const approveEmployeeEdit = createAsyncThunk(
  'timecardModal/approveEmployeeEdit',
  ({ shiftId, originPath }, { dispatch }) =>
    putJSON(routes.timecardRoute(shiftId, '/employee_clock_out'), {
      approve: true,
    }).then(response => {
      updateRowOfContext(originPath, dispatch, shiftId);
      return response;
    })
);

export const mergeShifts = createAsyncThunk(
  'timecardModal/mergeShifts',
  ({ scheduledShiftId, unscheduledShiftId }, { dispatch }) => {
    dispatch(updateRefreshTipOuts());

    return putJSON(routes.timecardRoute(unscheduledShiftId, '/fix_duplicate'), {
      dismiss: false,
      scheduled_shift_id: scheduledShiftId,
    });
  }
);

export const fetchShiftData = createAsyncThunk(
  'timecardModal/fetchShiftData',
  ({ id }) => fetchJSON(`/timesheets/timecards/${id}.json`),
  {
    condition: (props, { getState }) =>
      !getState()
        .getIn(['timecardModal', 'shifts'])
        .find(s => s.get('id') === props.id)
        .get('loaded'),
  }
);

export const fetchShiftOvertime = createAsyncThunk(
  'timecardModal/fetchShiftOvertime',
  ({ id }) => fetchJSON(`/timesheets/timecards/${id}/overtime.json`)
);

export const fetchLocationData = createAsyncThunk(
  'timecardModal/fetchLocationData',
  () => fetchJSON(`/timesheets/timecards/location_data.json`)
);

export const updateRowOfContext = (originPath, dispatch, id) => {
  const context = determineContext(originPath);
  if (context === 'daily_review') {
    dispatch(updateDailyReviewRow(id));
  } else {
    dispatch(updateRow(id, true));
  }
};

export const updateTimecardApproval = createAsyncThunk(
  'timecardModal/updateTimecardApproval',
  ({ id, approve, originPath }, { dispatch }) =>
    putJSON(`/timesheets/timecards/${id}/update_timecard_approval`, {
      approve,
    }).then(response => {
      updateRowOfContext(originPath, dispatch, id);

      return response;
    })
);

export const submitManagerNote = createAsyncThunk(
  'timecardModal/submitManagerNote',
  ({ note, id, originPath }, { dispatch }) =>
    postJSON(`/timesheets/timecards/${id}/create_note`, {
      note: { body: note.body, no_show: note.noShow },
    }).then(response => {
      updateRowOfContext(originPath, dispatch, id);
      return response;
    })
);

export const addShiftNoShowReason = createAsyncThunk(
  'timecardModal/addShiftNoShowReason',
  ({ reason, id, originPath }, { dispatch }) =>
    putJSON(`/timesheets/timecards/${id}/update_shift`, {
      shift: { no_show_reason: reason },
    }).then(response => {
      updateRowOfContext(originPath, dispatch, id);
      return response;
    })
);

export const deleteTimecard = createAsyncThunk(
  'timecardModal/deleteTimecard',
  async ({ id, originPath }, { dispatch, getState }) => {
    const isShiftEligibleForTipPooling = getState()
      .getIn(['timecardModal', 'shifts'])
      .find(s => s.get('id') === id)
      .get('tip_pooling_timecard_eligible');

    if (isShiftEligibleForTipPooling) dispatch(updateRefreshTipOuts());

    let url = `/timesheets/${id}/delete`;
    const context = determineContext(originPath);
    if (context === 'daily_review') {
      url += `?daily_review=true`;
    }
    return deleteJSON(url).then(response => {
      if (context === 'timesheets') {
        dispatch(updateRow(id, true));
      }

      if (context === 'daily_review') {
        return {
          ...response,
          id,
        };
      }
    });
  }
);

export const dismissMissedBreaks = createAsyncThunk(
  'timecardModal/dismissMissedBreaks',
  // eslint-disable-next-line no-unused-vars
  ({ id, mandated_break_id, waived, originPath }, { dispatch }) =>
    putJSON(`/timesheets/timecards/${id}/dismiss_missed_breaks`, {
      mandated_break_id,
      waived,
    }).then(() => {
      updateRowOfContext(originPath, dispatch, id);
    })
);

export const updateAutoClockOut = createAsyncThunk(
  'timecardModal/updateAutoClockOut',
  ({ id, status, originPath }, { dispatch }) =>
    putJSON(`/timesheets/timecards/${id}/update_auto_clock_out`, {
      status,
    }).then(() => {
      updateRowOfContext(originPath, dispatch, id);
    })
);

const timecardModalSlice = createSlice({
  name: 'timecardModal',
  initialState: fromJS(INITIAL_STATE),
  reducers: {
    openTimecardModal,
    closeTimecardModal,
    setChangedShiftId,
    setEditMode,
    addNewRoleOption: (state, action) =>
      state.setIn(['roles'], state.get('roles').push(fromJS(action.payload))),
    setValidationErrors,
    setCurrentShiftId: (state, action) => {
      // If we've already loaded a shift, then we don't need to set a loading indicator
      // again since the fetch request will not go through

      const isLoading = !state
        .get('shifts')
        .find(s => s.get('id') === action.payload)
        .get('loaded');

      return state.merge({
        isLoading,
        editMode: false,
        currentShiftId: action.payload,
      });
    },
  },
  extraReducers: {
    [submitTimecardData.pending]: state => state.merge({ isSubmitting: true }),
    [submitTimecardData.rejected]: (state, action) => {
      const newState = state.merge({ isSubmitting: false });
      return setValidationErrors(newState, { payload: action.payload });
    },
    [submitTimecardData.fulfilled]: (state, action) => {
      let newState = state.merge({ isSubmitting: false });
      newState = updateShiftData(newState, action);
      newState = setEditMode(newState, { payload: false });
      newState = setChangedShiftId(newState, { payload: action.payload.id });
      newState = setValidationErrors(newState, {
        payload: DEFAULT_VALIDATION_ERRORS,
      });

      // This is to support old backbone pages that call this timecard modal
      if (newState.get('backboneEventName')) {
        window.Backbone.Mediator.pub(newState.get('backboneEventName'), {
          type: 'edit',
          data: { shift_id: action.payload.id, ...action.meta.arg.data },
        });
      }

      return newState;
    },
    [fetchShiftData.rejected]: state => state.merge({ isLoading: false }),
    [fetchShiftData.pending]: state => state.set('isLoading', true),
    [fetchShiftData.fulfilled]: updateShiftData,
    [deleteTimecard.rejected]: state => state.merge({ isLoading: false }),
    [deleteTimecard.pending]: state => state.set('isLoading', true),
    [addShiftNoShowReason.pending]: state => state.set('isLoading', true),
    [addShiftNoShowReason.rejected]: state => state.merge({ isLoading: false }),
    [updateTimecardApproval.fulfilled]: (state, action) => {
      const index = state
        .get('shifts')
        .findIndex(shift => shift.get('id') === action.payload.shift_id);

      return state.mergeIn(['shifts', index], {
        locked_message: action.payload.locked_info,
      });
    },
    [fetchLocationData.fulfilled]: updateLocationData,
    [fetchShiftOvertime.fulfilled]: (state, action) => {
      const index = state
        .get('shifts')
        .findIndex(s => s === action.meta.arg.id);

      if (index && action.payload) {
        const issue = state
          .getIn(['shifts', index, 'issues'])
          ?.find(i => i.get('type') === 'overtime');

        if (!issue) {
          return state.mergeIn(
            ['shifts', index, 'issues'],
            state
              .getIn(['shifts', index, 'issues'])
              ?.push(action.payload)
              .toJS()
          );
        }
      }

      return state;
    },
    [mergeShifts.fulfilled]: (state, action) => {
      const unscheduledShiftIndex = state
        .get('shifts')
        .findIndex(s => s.get('id') === action.meta.arg.unscheduledShiftId);

      const scheduledShiftIndex = state
        .get('shifts')
        .findIndex(s => s.get('id') === action.meta.arg.scheduledShiftId);

      let newState = state.set(
        'currentShiftId',
        action.meta.arg.scheduledShiftId
      ); // set scheduled shifts as current shift

      if (scheduledShiftIndex) {
        newState = newState.setIn(
          ['shifts', scheduledShiftIndex, 'loaded'],
          false
        ); // refetch data for the scheduled shift
      } else {
        newState = newState.set(
          'shifts',
          newState
            .get('shifts')
            .push(
              fromJS({ id: action.meta.arg.scheduledShiftId, loaded: false })
            )
        );
      }

      if (unscheduledShiftIndex) {
        newState = newState.deleteIn(['shifts', unscheduledShiftIndex]); // delete unscheduled shift from the list if it's present
      }

      return newState;
    },
    [deleteTimecard.fulfilled]: state => {
      state = state.set('isLoading', false);
      const index = state
        .get('shifts')
        .findIndex(s => s.get('id') === state.get('currentShiftId'));

      state = state.merge({
        isOpen: false,
        currentShiftId: null,
      });

      return state.deleteIn(['shifts', index]);
    },
    [submitManagerNote.fulfilled]: (state, action) => {
      const index = state
        .get('shifts')
        .findIndex(s => s.get('id') === action.meta.arg.id);
      return state.mergeIn(
        ['shifts', index, 'notes'],
        state.getIn(['shifts', index, 'notes']).push(action.payload).toJS()
      );
    },
    [approveEmployeeEdit.fulfilled]: (state, action) => {
      const shiftIndex = state
        .get('shifts')
        .findIndex(s => s.get('id') === action.meta.arg.shiftId);

      const issueIndex = state
        .getIn(['shifts', shiftIndex, 'issues'])
        .findIndex(i => i.get('type') === 'employee_edit');

      return state.deleteIn(['shifts', shiftIndex, 'issues', issueIndex]);
    },
    [addShiftNoShowReason.fulfilled]: (state, action) => {
      state = state.set('isLoading', false);

      const index = state
        .get('shifts')
        .findIndex(s => s.get('id') === action.meta.arg.id);

      return state.mergeIn(['shifts', index], {
        ...action.payload,
        loaded: true,
      });
    },
    [dismissMissedBreaks.fulfilled]: (state, action) => {
      const shiftIndex = state
        .get('shifts')
        .findIndex(s => s.get('id') === action.meta.arg.id);

      const processBreakIssue = issueType => {
        const issueIndex = state
          .getIn(['shifts', shiftIndex, 'issues'])
          .findIndex(i => i.get('type') === issueType);

        if (issueIndex !== -1) {
          const mandatedBreakIndex = state
            .getIn([
              'shifts',
              shiftIndex,
              'issues',
              issueIndex,
              'meta',
              'missing_breaks',
            ])
            .findIndex(
              mb =>
                mb.get('mandated_break_id') ===
                action.meta.arg.mandated_break_id
            );

          if (mandatedBreakIndex !== -1) {
            return state.deleteIn(['shifts', shiftIndex, 'issues', issueIndex]);
          }
        }
      };

      let newState = processBreakIssue('missing_unpaid_breaks');
      if (newState) return newState;

      newState = processBreakIssue('missing_paid_breaks');
      if (newState) return newState;

      return state;
    },

    [updateAutoClockOut.fulfilled]: (state, action) => {
      const shiftIndex = state
        .get('shifts')
        .findIndex(s => s.get('id') === action.meta.arg.id);

      const autoClockOutIssueIndex = state
        .getIn(['shifts', shiftIndex, 'issues'])
        .findIndex(i => i.get('type') === 'auto_clock_out');

      return state.deleteIn([
        'shifts',
        shiftIndex,
        'issues',
        autoClockOutIssueIndex,
      ]);
    },
  },
});

export const timecardModalReducer = timecardModalSlice.reducer;
export const timecardModalActions = timecardModalSlice.actions;
