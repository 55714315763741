import React from 'react';
import { connect } from 'react-redux';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import Button from 'fe-design-base/molecules/Button';

import { getCurrentLocationName } from 'selectors/session';

import PurchaseMethodModule from 'features/biller/implementations/stripe/PaymentMethodModule/PurchaseMethodModule';

import { toI18n } from 'util/i18n';

interface CheckoutProps {
  onConfirmAndPay: () => void;
  onSuccess: () => void;
  onLoading: (isLoading: boolean) => void;
  isLoading: boolean;
  locationName: string;
}
const Checkout: React.FC<CheckoutProps> = ({
  locationName,
  onSuccess,
  onConfirmAndPay,
  onLoading,
  isLoading,
}) => (
  <>
    <Box ph={32}>
      <Box gap={12} column>
        <Text variant="heading2" i18n="checkout_drawer.tip_manager.title" />
        <Text
          variant="body"
          color="mono900"
          i18n="checkout_drawer.tip_manager.subtitle"
          i18nProps={{ locationName }}
          asHTML
        />
      </Box>
      <PurchaseMethodModule
        onSuccess={onSuccess}
        onLoading={onLoading}
        price={{ amount: 25, priceSystemId: 'price_1PWNljKwepuboAKVEmmmi6EY' }}
      />
      <Box mt={24}>
        <Text
          variant="bodySm"
          color="mono900"
          i18n="checkout_drawer.tip_manager.footer"
          asHTML
        />
      </Box>
    </Box>
    <Box sticky hcenter mt={32} p={32} bottom={0} bgcolor="mono0">
      <Button
        fullWidth
        variant="primary"
        onClick={onConfirmAndPay}
        isLoading={isLoading}
      >
        {toI18n('checkout_drawer.tip_manager.cta')}
      </Button>
    </Box>
  </>
);

export default connect(state => ({
  locationName: getCurrentLocationName(state),
}))(Checkout);
