import { Map } from 'immutable';
import { createSelector } from 'reselect';

import * as entitiesSelectors from 'selectors/entities';
import * as fetchingSelectors from 'selectors/fetching';
import * as sessionSelectors from 'selectors/session';

export const getApplyToAllLocations = state =>
  state.getIn(['settings', 'applyToAllLocations']);

// # API Settings Form-specific selectors
export const getIsRequestingKey = state =>
  state.getIn(['settings', 'apiForm', 'requestingKey']);

const getSettingsLoaded = entitiesSelectors.createLoadedSelector(
  'locations',
  'SETTINGS'
);

export const getUserSettingsLocationOptions = createSelector(
  sessionSelectors.getCurrentUser,
  user => {
    if (!user.get('locations')) {
      return [];
    }

    return user
      .get('locations')
      .sortBy(locations => locations.get('name'))
      .reduce((memo, location) => {
        memo.push({
          label: location.get('name'),
          value: location.get('id'),
        });
        return memo;
      }, []);
  }
);

export const getUserSettingsLocations = createSelector(
  sessionSelectors.getCurrentUser,
  user => {
    if (!user.get('locations')) {
      return [];
    }

    const locations = user.get('locations').reduce((memo, location) => {
      memo[location.get('id')] = location;
      return memo;
    }, []);

    return Map({ ...locations });
  }
);

export const getCompanyUsersDropdownOptions = createSelector(
  entitiesSelectors.getCompanyUsers,
  sessionSelectors.getCurrentCompanyOwner,
  (users, owner) =>
    users
      .filterNot(
        user =>
          user.get('email') === owner.get('email') || user.get('email') === null
      )
      .map(user => ({
        value: `${user.get('first_name')} ${user.get('last_name')} (${user.get(
          'email'
        )}) | ${user.get('id')}`,
        label: `${user.get('first_name')} ${user.get('last_name')} (${user.get(
          'email'
        )})`,
      }))
      .toList()
);

export const getLocationForForms = state =>
  entitiesSelectors.getLocationFromProps(state, {
    locationId: sessionSelectors.getCurrentLocationId(state),
  });

export const getIsLoadingLocation = state =>
  state.getIn(['settings', 'isLoading']);
export const getIsLoadingUser = state =>
  state.getIn(['settings', 'isLoadingUser']);

export const getIsLoading = createSelector(
  getIsLoadingUser,
  getIsLoadingLocation,
  (isLoadingUser, isLoadingLocation) => isLoadingUser || isLoadingLocation
);

export const getPartnerLocationsImportIsLoading = state =>
  state.getIn(['settings', 'partnerLocationsImportIsLoading']);

export const getIsLoadingMessagingSettingsChannels = createSelector(
  entitiesSelectors.createLoadedSelector('channels', 'SETTINGS'),
  channelsLoaded => !channelsLoaded
);

export const getShowLoading = createSelector(
  getSettingsLoaded,
  getIsLoading,
  (isLoaded, isLoading) => !isLoaded || isLoading
);

export const getLocations =
  entitiesSelectors.createLocationsForViewSelector('settings');

export const selectSecuritySettings = createSelector(
  sessionSelectors.getCurrentUser,
  user => ({
    mfaEnabled: Boolean(user.get('mfa_enabled')),
    mfaPhone: user.get('mfa_phone') || user.get('phone') || '',
  })
);

export const selectLastPasswordResetAt = createSelector(
  sessionSelectors.getCurrentUser,
  user => user.get('last_password_reset_at')
);

// Billing
const getBillingSettings = state => state.getIn(['settings', 'billing']);
export const getTransactions = state =>
  getBillingSettings(state).get('transactions');
export const getSubscriptions = state =>
  getBillingSettings(state).get('subscriptions');
export const getBillingIsLoading = state =>
  getBillingSettings(state).get('isLoading');
export const getBillingIsDirty = state =>
  getBillingSettings(state).get('isDirty');

export const getLocationSubscriptionOptions = createSelector(
  getSubscriptions,
  subscriptions =>
    subscriptions
      .map(subscription => ({
        label: subscription.get('location'),
        value: subscription.get('location_id'),
      }))
      .toJS()
);

export const getPayrollSubscription = state =>
  getBillingSettings(state).get('payrollSubscription');

export const getIsSaving = (state, { formName }) =>
  fetchingSelectors.getIsFetching(state, {
    requestId: `save-location-settings__${formName}`,
  });

export const getLastSaved = state => state.getIn(['settings', 'lastSaved']);

const buildGeneralSelector = key => state =>
  state.getIn(['settings', 'general', key]);

export const getHighlightedBlock = buildGeneralSelector('highlightedBlock');

export const getRequestIdSaving = (state, requestId) =>
  state.hasIn(['settings', 'isSaving', requestId]);

export const getPayrollModalErrors = state =>
  state.getIn(['settings', 'payrollModalErrors']);
