import { toI18n } from 'util/i18n';
import {
  EVENT_ACTIONS as BASE_EVENT_ACTIONS,
  TRACK_ACTION_TYPES,
} from 'util/tracking_constants';

export const DEFAULT_PARTNER_KEY = 'cash_register';

export const DEFAULT_START_WEEK = 1;

export const DEFAULT_V3_ROUTE = '/onboarding/sign-up/v3';

export const OLD_NATIVE_CLIENT_ROUTES = {
  OWNER_INFO: '/onboarding/sign-up/owner_account',
  OWNER_INFO2: '/onboarding/sign-up/owner-account',
};

export const ROUTES = {
  ACCOUNT_DETAILS: `${DEFAULT_V3_ROUTE}/create-owner-account`,
  ACCOUNT_VERIFICATION: `${DEFAULT_V3_ROUTE}/user-verification`,
  BUSINESS_INFO: `${DEFAULT_V3_ROUTE}/business-info`,
  CHOOSE_TYPE: '/onboarding/sign-up',
  COMPANY_STEP: '/onboarding/sign-up/company',
  EMPLOYEE_STEP: '/onboarding/sign-up/employee',
  EMPLOYEE_CHECK_STEP: '/onboarding/sign-up/employee-check',
  EMPLOYEE_ASK_MANAGER: '/onboarding/sign-up/employee/ask-manager',
  EMPLOYEE_ASK_MANAGER_WITH_FIND:
    '/onboarding/sign-up/employee/ask-manager-with-find',
  EMPLOYEE_ALREADY_INVITED: '/onboarding/sign-up/employee/already-invited',
  EMPLOYEE_PHONE_SIGN_IN: '/onboarding/sign-up/employee/phone-sign-in',
  EMPLOYEE_FIND_LOCATION: '/onboarding/sign-up/employee/find-location',
  EMPLOYEE_ACCOUNT_STEP: '/onboarding/sign-up/employee-account',
  EMPLOYEE_SUCCESS_STEP: '/onboarding/sign-up/employee-success',
  JOIN_COMPANY_STEP: '/onboarding/sign-up/join-company',
  OWNER_ACCOUNT_STEP: '/onboarding/sign-up/owner-account',
  OWNER_INFO: `${DEFAULT_V3_ROUTE}/owner-info`,
  OWNER_MOTIVATION: `${DEFAULT_V3_ROUTE}/owner-motivation`,
  SUCCESS_STEP: '/onboarding/sign-up/success',
  QSG_SPLASH: '/quick_start_guide/splash',
  V3_ROOT: DEFAULT_V3_ROUTE,
};

export const FIELDS = {
  FULL_NAME: 'full_name',
  EMAIL: 'email',
  PASSWORD: 'password',
  PHONE: 'phone',
  COMPANY_NAME: 'company_name',
  COMPANY_ZIP: 'company_zip',
  INDUSTRY: 'industry',
  TEAM_SIZE: 'team_size',
  CITY: 'city',
  COMPANY: 'company',
  WOM: 'wom',
  CURRENT_PAYROLL_SOLUTION: 'current_payroll_solution',
  PAYROLL_NEEDS: 'payroll_needs',
  TEAM_MOTIVATION: 'team_motivation',
  BUSINESS_NAME: 'business_name',
  BUSINESS_ZIP: 'business_zip',
};

export const EVENT_ACTIONS = {
  ...BASE_EVENT_ACTIONS,
  APP_BUTTON_CLICKED: 'App Button Clicked',
  CHECKBOX_CLICKED: 'Checkbox Clicked',
  CLICKED_MOBILE_APP_MODAL: 'Get Mobile App Modal Submitted',
  CONTINUE_CLICKED: 'Continue Clicked',
  CURRENT_PAYROLL_SOLUTION_OPTION_SELECTED:
    'Current Payroll Solution Option Selected',
  DROPDOWN_CLICKED: 'Dropdown Clicked',
  ERROR_MESSAGE_SHOWN: 'Error Message Shown',
  EMPLOYEE_OPTION_CLICKED: 'Employee Option Clicked',
  FIND_LOCATION_CLICKED: 'Find Location Clicked',
  HOMEBASE_LOGO_CLICKED: 'Homebase Logo Clicked',
  INDUSTRY_OPTION_CLICKED: 'Industry Option Clicked',
  LOGIN_CLICKED: 'Login link Clicked',
  PAYROLL_NEEDS_OPTION_SELECTED: 'Payroll Needs Option Selected',
  ONE_LAST_STEP_CLICKED: 'One Last Step Clicked',
  OWNER_MOTIVATION_CLICKED: 'Owner Motivation Clicked',
  OWNER_OPTION_CLICKED: 'Owner Option Clicked',
  SIGN_IN_CLICKED: 'Sign In Clicked',
  TEAM_SIZE_OPTION_CLICKED: 'Team Size Option Clicked',
  USE_CASE_CLICKED: 'Use Case Clicked',
  VIEWED_MOBILE_APP_MODAL: 'Get Mobile App Modal Shown',
  WOM_OPTION_SELECTED: 'WOM Option Selected',
};

export const CLICK_ITEMS = {
  LOGO: 'homebase logo',
  SIGN_IN: 'sign in',
  TYPE_OWNER: 'owner',
  TYPE_EMPLOYEE: 'employee',
  TOS: 'terms_of_service',
  PRIVACY_POLICY: 'privacy_policy',
  USER_AGREEMENT: 'user_agreement',
  CONTINUE: 'continue',
  APP_STORE: 'app_store',
  GOOGLE_PLAY: 'google_play',
  OWNER_MOTIVATION: 'owner motivation',
  LOGIN_CLICKED: 'login',
};

export const FIELD_TYPES = {
  TEXT: 'text',
  PASSWORD: 'password',
  EMAIL: 'email',
  PHONE: 'phone',
};

const trackFocusData = (field, accountType) => [
  EVENT_ACTIONS.FIELD_FOCUSED,
  TRACK_ACTION_TYPES.FOCUS,
  {
    field,
    account_type: accountType,
  },
];

const trackClickData = (ctaEvent, field = {}) => [
  ctaEvent,
  TRACK_ACTION_TYPES.CLICK,
  field,
];

const trackPageViewed = () => [
  EVENT_ACTIONS.PAGE_VIEWED,
  TRACK_ACTION_TYPES.VIEW,
];

export const TRACK_UX_PAYLOADS = {
  PAGE_VIEWED: trackPageViewed(),
  FOCUSED_FULL_NAME: trackFocusData(FIELDS.FULL_NAME, 'owner'),
  FOCUSED_EMAIL: trackFocusData(FIELDS.EMAIL, 'owner'),
  FOCUSED_PASSWORD: trackFocusData(FIELDS.PASSWORD, 'owner'),
  FOCUSED_PHONE: trackFocusData(FIELDS.PHONE, 'owner'),
  FOCUSED_COMPANY_NAME: trackFocusData(FIELDS.COMPANY_NAME),
  FOCUSED_COMPANY_ZIP: trackFocusData(FIELDS.COMPANY_ZIP),
  FOCUSED_BUSINESS_NAME: trackFocusData(FIELDS.BUSINESS_NAME),
  FOCUSED_BUSINESS_ZIP: trackFocusData(FIELDS.BUSINESS_ZIP),
  CLICK_LOGIN_HEADER: trackClickData(EVENT_ACTIONS.LINK_CLICKED, {
    link_text: 'Sign in',
    link_url: 'https://app.joinhomebase.com/accounts/sign-in',
  }),
  CLICK_GET_STARTED: trackClickData(EVENT_ACTIONS.BUTTON_CLICKED, {
    button_text: 'Get Started',
  }),
  CLICK_CONTINUE: trackClickData(EVENT_ACTIONS.BUTTON_CLICKED, {
    button_text: 'Continue',
  }),
  CLICK_NOT_SURE_YET: trackClickData(EVENT_ACTIONS.BUTTON_CLICKED, {
    button_text: 'Not Sure Yet',
  }),
  CLICK_OWNER_MOTIVATION: trackClickData(EVENT_ACTIONS.CHECKBOX_CLICKED),
  CLICK_ONE_LAST_STEP: trackClickData(EVENT_ACTIONS.BUTTON_CLICKED, {
    button_text: 'One Last Step',
  }),
  CLICK_BUSINESS_TYPE: trackClickData(EVENT_ACTIONS.DROPDOWN_CLICKED),
  CLICK_TEXT_LINK: trackClickData(EVENT_ACTIONS.CHECKBOX_CLICKED),
  CLICK_BUTTON: (button_text, step_label) =>
    trackClickData(EVENT_ACTIONS.BUTTON_CLICKED, {
      button_text,
      step_label,
    }),
};

const TEAM_SIZE_OPTIONS = ['xs', 'sm', 'med', 'lg', 'xl'];

export const TEAM_SIZE_SELECT_OPTIONS = TEAM_SIZE_OPTIONS.map(value => ({
  value,
  label: toI18n('onboarding.questions_modal.team_size')[value],
}));

// these keys are referenced in config/settings.yml under
// hiring.popular_roles_from_business_type_keys
const BUSINESS_TYPE_KEYS = [
  'food_coffee',
  'food_table',
  'food_counter',
  'food_bar',
  'health',
  'fitness',
  'home',
  'leisure',
  'prof_service',
  'retail',
  'transportation',
  'education',
  'non_profit',
  'accounting',
  'other',
];

export const BUSINESS_TYPE_SELECT_OPTIONS = BUSINESS_TYPE_KEYS.map(key => ({
  label: toI18n('onboarding.questions_modal.business_types')[key],
  value: key,
}));

const LOCATIONS_COUNT_OPTIONS = [
  ['just_one', '1_location'],
  ['two', '2_locations'],
  ['3_to_5', '3-5_locations'],
  ['6_plus', '6+_locations'],
];

export const LOCATIONS_COUNT_SELECT_OPTIONS = LOCATIONS_COUNT_OPTIONS.map(
  ([i18nKey, value]) => ({
    value,
    label: toI18n('onboarding.questions_modal.locations_count')[i18nKey],
  })
);

const CURRENTLY_USING_OPTIONS = [
  'pen',
  'excel',
  'new_business',
  'different_app',
  'other',
];

export const CURRENTLY_USING_SELECT_OPTIONS = CURRENTLY_USING_OPTIONS.map(
  value => ({
    value,
    label: toI18n('onboarding.questions_modal.currently_using')[value],
  })
);

export const REDIRECT_TO_COMPANY_DASHBOARD_RESPONSES = [
  '2_locations',
  '3-5_locations',
  '6+_locations',
];

const PAYROLL_TYPE_KEYS = [
  'payroll_software',
  'manually',
  'accountant',
  'no_solution',
];

const PAYROLL_TYPE_KEYS_SEGMENTATION = [
  'no_solution',
  'payroll_software',
  'accountant',
  'manually',
];

export const PAYROLL_TYPE_SELECT_OPTIONS = PAYROLL_TYPE_KEYS.map(key => ({
  label: toI18n('onboarding.payroll_questions.question_one_options')[key],
  value: key,
}));

export const PAYROLL_TYPE_SELECT_OPTIONS_V2 = PAYROLL_TYPE_KEYS.map(key => ({
  label: toI18n('onboarding.payroll_questions.question_one_options_v2')[key],
  value: key,
}));

export const PAYROLL_TYPE_SELECT_OPTIONS_SEGMENTATION =
  PAYROLL_TYPE_KEYS_SEGMENTATION.map(key => ({
    label: toI18n('onboarding.payroll_questions.question_one_options_v3')[key],
    value: key,
  }));

export const SLICE_NAME = 'signUp';

export const SIGNUP_STEPS = {
  OWNER_INFO: {
    id: 1,
    name: 'ownerInfo',
    stepLabel: 'welcome',
  },
  OWNER_MOTIVATION: {
    id: 2,
    name: 'ownerMotivation',
    stepLabel: 'use_type',
  },
  BUSINESS_INFO: {
    id: 3,
    name: 'businessInfo',
    stepLabel: 'business_info',
  },
  ACCOUNT_DETAILS: {
    id: 4,
    name: 'accountDetails',
    stepLabel: 'user_info',
  },
  ACCOUNT_VERIFICATION: {
    id: 5,
    name: 'accountVerification',
    stepLabel: 'email_verification',
  },
};

export const USER_MOTIVATION_KEYS = {
  SCHEDULING: 'scheduling',
  TIME_TRACKING: 'timeTracking',
  PAYROLL: 'payroll',
};

export const ENDPOINTS = {
  CREATE_BUSINESS: '/sign_up/create_business',
  CREATE_EMPLOYEE_BUSINESS: '/sign_up/create_employee_business',
  CREATE_JOB: '/onboarding/employees/create_job',
  EMPLOYEE_CHECK: '/onboarding/employee_check',
  LOCATION_SEARCH: '/onboarding/employees/search_location.json',
  SEND_EMAIL_CONFIRMATION_EMAIL: '/sign_up/send_email_confirmation_email',
  SKIP_EMAIL_CONFIRMATION: '/sign_up/skip_confirmation',
  UPDATE: '/onboarding/update',
  PRE_SIGNUP_EXPERIMENT_ENROLL: '/pre_signup_enroll',
};

export const STEP_LABELS = {
  USER_ACCOUNT_TYPE: 'user_account_type',
  USER_INFO: 'user_info',
  BUSINESS_INFO: 'business_info',
  USE_CASE_SELECTION: 'use_case_selection',
  VBO_QUESTIONS: 'vbo_questions',
  EMPLOYEE_CHECK: 'employee_check',
  EMPLOYEE_ALREADY_INVITED: 'employee_already_invited',
  EMPLOYEE_NOT_FOUND: 'employee_not_found',
  EMPLOYEE_JOIN_LOCATION: 'employee_join_location',
  EMPLOYEE_JOIN_REQUEST: 'employee_join_request',
  EMPLOYEE_JOIN_SUCCESS: 'employee_join_success',
  EMPLOYEE_PHONE_SIGN_IN: 'employee_phone_sign_in',
  INVITE_TEAMMATE: 'invite_teammate',
  SUCCESS_LOADING_SCREEN: 'success_loading_screen',
};

export const ACCOUNT_TYPES = {
  OWNER: 'owner',
  EMPLOYEE: 'employee',
};

export const CURRENT_PAYROLL_SOLUTION_OPTIONS = {
  0: 'payroll_software',
  1: 'manually',
  2: 'accountant',
  3: 'no_solution',
};

export const INFLUENCER_CAMPAIGNS = [
  'green-industry-guy-feb23',
  'senoreata-feb23',
  'drew-talbert-feb23',
  'alex-feb23',
  'evanice-feb23',
];

export const WOM_OPTIONS = [
  'search',
  'recommendation',
  'pos_or_payroll',
  'review',
  'social',
  'news',
  'mail_flyer',
  'radio_podcast',
  'other',
];

export const BUSINESS_AGE = {
  0: 'not_open_yet',
  1: 'less_than_6_months',
  2: '6-12_months',
  3: '1-3_years',
  4: '3+_years',
};

const LOCATION_COUNT_KEYS = [
  'single_location',
  'expanding_soon',
  'multi_location',
];

export const LOCATION_COUNT_OPTIONS = LOCATION_COUNT_KEYS.map(key => ({
  label: toI18n(
    'onboarding.v3.company_info_step.location_count_question_answers'
  )[key],
  value: key,
}));

export const LOCATION_COUNT_OPTIONS_UPDATED = LOCATION_COUNT_KEYS.map(key => ({
  label: toI18n('sign_up.business_info.location_count_question_answers')[key],
  value: key,
}));

const XS = 'xs';
const SM = 'sm';
const LG = 'lg';
const XL = 'xl';

export const BUSINESS_SIZE_OPTIONS = [
  { value: XS, label: '1-5' },
  { value: SM, label: '6-10' },
  { value: '11-19', label: '11-19' },
  { value: '20-29', label: '20-29' },
  { value: LG, label: '30-50' },
  { value: XL, label: '51+' },
];

export const UPDATED_BUSINESS_SIZE_OPTIONS = [
  { value: XS, label: '1-5' },
  { value: SM, label: '6-10' },
  { value: '11-19', label: '11-19' },
  { value: '20-29', label: '20-29' },
  { value: LG, label: '30-49' },
  { value: XL, label: '50+' },
];

export const EXTENDED_BUSINESS_SIZE_OPTIONS = [
  { value: '50-99', label: '50-99' },
  { value: '100-199', label: '100-199' },
  { value: '200+', label: '200+' },
];

export const TRY_OPTIONS_TO_ADD_TEAMMATE = [
  'with_close_teammate',
  'with_whole_team',
];

export const TRY_OPTIONS_SELECTION = [
  ...TRY_OPTIONS_TO_ADD_TEAMMATE,
  'try_myself',
];

export const TRY_OPTIONS_SELECT_OPTIONS = TRY_OPTIONS_SELECTION.map(key => ({
  label: toI18n(`onboarding.v3.try_options_step.${key}.invite_option`),
  value: key,
}));
