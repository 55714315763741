import React, { useCallback, useEffect, useState } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import Box from 'fe-design-base/atoms/Box';
import Icon from 'fe-design-base/atoms/Icon';
import Text from 'fe-design-base/atoms/Text/Text';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import { getHasManagersFeatureAccess } from 'selectors/addTeam';
import { getPacketProps } from 'selectors/drawers';
import {
  getCurrentLocationName,
  getHasOnboardingDocs,
} from 'selectors/session';

import { cxHelpers } from 'util/className';

import SectionTitle from '../../components/SectionTitle';
import TaxClassification from '../../components/TaxClassification';

import AccessLevel from './AccessLevel';
import JobRoles from './JobRoles';

const { cx } = cxHelpers('JobDetailsSection');

const JobDetailsSection = ({
  companyAndSelectedRoles,
  currentLocationName,
  hasOnboardingDocsAccess,
  isPayrollEnrolled,
  managersCanAccess,
  newHireOnboardingActivated,
  rehireLevel,
  selectedRoles,
  setRoleFieldValue,
  setSelectedRoles,
  showFormWithSteps,
}) => {
  const { setFieldValue, values } = useFormikContext();

  const [numAdditionalRoleWage, setNumAdditionalRoleWage] = useState(0);

  useEffect(() => {
    setNumAdditionalRoleWage(values.wageAttributes.length);

    const preSelectedRoles = [];

    preSelectedRoles.push(values.defaultRoleName);
    values.wageAttributes.map(wageAttribute =>
      preSelectedRoles.push(wageAttribute.role_name)
    );

    setSelectedRoles(preSelectedRoles);
  }, [setSelectedRoles, values.defaultRoleName, values.wageAttributes]);

  const updateSelectedRoles = useCallback(
    (reason, currentRoleName, roleName) => {
      if (reason === 'clear') {
        const index = selectedRoles.indexOf(currentRoleName);
        if (index > -1) {
          selectedRoles.splice(index, 1);
          setSelectedRoles([...selectedRoles]);
        }
      } else {
        // eslint-disable-next-line no-shadow
        setSelectedRoles(selectedRoles => [...selectedRoles, roleName]);
      }
    },
    [selectedRoles, setSelectedRoles]
  );

  const handleAddRoleWage = useCallback(() => {
    setFieldValue(`wageAttributes.${numAdditionalRoleWage}.role_name`, '');
    setFieldValue(`wageAttributes.${numAdditionalRoleWage}.wage_rate`, '');
    setFieldValue(`wageAttributes.${numAdditionalRoleWage}.wage_type`, 0);
    setNumAdditionalRoleWage(numAdditionalRoleWage + 1);
  }, [numAdditionalRoleWage, setFieldValue]);

  const boxStyle = showFormWithSteps
    ? {
        bgcolor: 'mono0',
        bradiusm: true,
        bcolor: 'mono300',
        bw: 1,
        p: 20,
      }
    : {};

  return (
    <Box className={cx()} mv={28} {...boxStyle}>
      <SectionTitle section="job_details" />
      <Box vcenter row>
        <Icon mr={4} size="xsmall" iconName="Location" color="mono700" />
        <Text color="mono700" variant="captions">
          {currentLocationName}
        </Text>
      </Box>

      <Box mt={24}>
        <JobRoles
          companyAndSelectedRoles={companyAndSelectedRoles}
          selectedRoles={selectedRoles}
          setRoleFieldValue={setRoleFieldValue}
          onAddRoleWage={handleAddRoleWage}
          onUpdateSelectedRoles={updateSelectedRoles}
          numAdditionalRoleWage={numAdditionalRoleWage}
          onSetNumAdditionalRoleWage={setNumAdditionalRoleWage}
          rehireLevel={rehireLevel}
        />

        {managersCanAccess && (
          <>
            <Box mt={24}>
              <AccessLevel />
            </Box>
            {newHireOnboardingActivated &&
              hasOnboardingDocsAccess &&
              !isPayrollEnrolled && (
                <Box mt={24}>
                  <TaxClassification />
                </Box>
              )}
          </>
        )}
      </Box>
    </Box>
  );
};

JobDetailsSection.propTypes = {
  companyAndSelectedRoles: ImmutablePropTypes.list,
  currentLocationName: PropTypes.string.isRequired,
  hasOnboardingDocsAccess: PropTypes.bool,
  isPayrollEnrolled: PropTypes.bool,
  managersCanAccess: PropTypes.bool,
  rehireLevel: PropTypes.string,
  selectedRoles: PropTypes.array,
  setRoleFieldValue: PropTypes.func,
  setSelectedRoles: PropTypes.func,
};

export default connect(state => ({
  currentLocationName: getCurrentLocationName(state),
  hasOnboardingDocsAccess: getHasOnboardingDocs(state),
  managersCanAccess: getHasManagersFeatureAccess(state),
  newHireOnboardingActivated: getPacketProps(state).newHireOnboardingActivated,
}))(JobDetailsSection);
