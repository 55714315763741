import { deleteJSON } from 'api/fetch';

import { BILLING_CYCLES } from 'features/biller/constants';
import { isDowngrading } from 'features/biller/util';
import { TIER_NAMES } from 'features/tiers/constants';

export const PAID_TIER_NAMES = ['essentials', 'plus', 'all-in-one'];

export const isFeatureAvailableOnTier = (tier, minimum_tier) => {
  const result =
    PAID_TIER_NAMES.indexOf(tier) >= PAID_TIER_NAMES.indexOf(minimum_tier);
  return result;
};

// visiting '/manage_plans?q=annual' => displays annual plans
// visiting '/manage_plans?q=monthly' => displays monthly plans
export const getBillingCycleIsAnnual = payload => {
  const billingFrequency = new URLSearchParams(window.location.search).get('q');

  if (billingFrequency === BILLING_CYCLES.annual) return true;

  if (billingFrequency === BILLING_CYCLES.monthly) return false;

  if (payload.billed_annually) return true;

  return false;
};

export const selectedBillingCycle = billingCycleAnnual => {
  if (billingCycleAnnual) return BILLING_CYCLES.annual;

  return BILLING_CYCLES.monthly;
};

export const proposePlanToUser = ({
  inTrial,
  tierName,
  basicTier,
  suggestedPlan,
  isCurrentPlan,
  starterPackageEnabled,
  desktopView,
}) => {
  if (starterPackageEnabled) return false;

  if (basicTier) return tierName === TIER_NAMES.ALL_IN_ONE;

  if (inTrial) return isCurrentPlan;

  if (suggestedPlan) return suggestedPlan;

  return !desktopView;
};

export const planCardCtaType = ({
  tierId,
  tierName,
  isSubscribedAnnually,
  hasActiveTrialPeriod,
  currentLocationTierId,
  isSelectedBillingCycleAnnual,
  locationIsOnStarterPayrollPackage,
}) => {
  const isCurrentTier = tierId === currentLocationTierId;

  if (
    !isCurrentTier ||
    (isCurrentTier && hasActiveTrialPeriod) ||
    locationIsOnStarterPayrollPackage
  ) {
    return 'choose_plan';
  }

  if (
    (isCurrentTier && isSubscribedAnnually === isSelectedBillingCycleAnnual) ||
    (isCurrentTier && tierName === TIER_NAMES.BASIC)
  ) {
    return 'current_plan';
  }

  if (isCurrentTier && isSubscribedAnnually !== isSelectedBillingCycleAnnual) {
    return isSelectedBillingCycleAnnual
      ? 'switch_to_annual'
      : 'switch_to_monthly';
  }
};

export const onClickChoosePlanForDemoCompany = async () => {
  await deleteJSON('/payroll/demo/end');

  window.location = '/onboarding/sign-up';
};

export const showDowngradesWorkflowBottomDrawer = ({
  tierName,
  hasActiveTrial,
  locationBiller,
  locationTierName,
  isSubscribedAnnually,
  isPastDueAfterDeadline,
  isSelectedBillingCycleAnnual,
}) => {
  const isToBasic = tierName === TIER_NAMES.BASIC;
  const isValidDowngrade = isDowngrading(locationTierName, tierName);
  const isDowngradingToPaidAnnualPlan =
    !isToBasic && isSelectedBillingCycleAnnual;

  if (
    locationBiller?.toLowerCase() !== 'stripe' ||
    hasActiveTrial ||
    isPastDueAfterDeadline ||
    isSubscribedAnnually ||
    isDowngradingToPaidAnnualPlan
  ) {
    return false;
  }

  if (isToBasic || isValidDowngrade) {
    return true;
  }
};
