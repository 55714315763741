import React, { ReactNode, useCallback, useEffect, useMemo } from 'react';
import MUIDialog from '@mui/material/Dialog';
import useId from '@mui/material/utils/useId';
import withCx, { CxProps } from 'fe-core/util/withCx';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import ActionButtons, {
  ActionButtonsProps,
} from 'fe-design-base/molecules/ActionButtons';
import IconButton from 'fe-design-base/molecules/IconButton';
import { useIsMediumScreen } from 'fe-design-base/utils/useMediaQuery';

import { EVENT_ACTIONS, TRACK_ACTION_TYPES } from 'util/tracking_constants';
import { useTrackUx } from 'util/uxEvents';

interface DialogProps extends ActionButtonsProps {
  open: boolean;
  className?: string;
  title?: string;
  width?: number | string;
  bgcolor?: string;
  noPadding?: boolean;
  hideCloseIcon?: boolean;
  disableBackdropClick?: boolean;
  fullScreen?: boolean;
  onClose: (event: object, reason: string) => void;
  children: (close: (event: object, reason: string) => void) => ReactNode;
  uxElement?: string;
}

const Dialog = ({
  className,
  open,
  title,
  width = 'fit-content',
  noPadding,
  hideCloseIcon,
  onClose,
  disableBackdropClick,
  fullScreen,
  bgcolor,
  children,
  uxElement,
  cx,
  cxEl,
  ...actionButtonsProps
}: DialogProps & CxProps) => {
  const trackUx = useTrackUx(
    useMemo(
      () => ({
        element: uxElement,
      }),
      [uxElement]
    ) as any
  );

  const handleClose = useCallback(
    (event, reason) => {
      if (hideCloseIcon && reason === 'escapeKeyDown') return;
      if (disableBackdropClick && reason === 'backdropClick') return;

      onClose?.(event, reason);
    },
    [disableBackdropClick, hideCloseIcon, onClose]
  );

  const handleCloseClick = useCallback(
    e => {
      if (uxElement)
        trackUx(EVENT_ACTIONS.DISMISS_CLICKED, TRACK_ACTION_TYPES.CLICK);
      onClose(e, 'closeButtonClick');
    },
    [onClose, trackUx, uxElement]
  );

  useEffect(() => {
    if (open && uxElement)
      trackUx(EVENT_ACTIONS.MODAL_VIEWED, TRACK_ACTION_TYPES.VIEW);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, uxElement]);

  const titleId = useId();
  const descriptionId = useId();
  const isFullScreen = !useIsMediumScreen() || fullScreen;
  const paperProps = useMemo(() => {
    if (isFullScreen || !width) return {};

    return {
      sx: {
        width,
      },
    };
  }, [isFullScreen, width]);

  return (
    <MUIDialog
      className={`${cx()}${className ? ` ${className}` : ''}`}
      open={open}
      onClose={handleClose}
      arial-labelledby={titleId}
      arial-describedby={descriptionId}
      fullScreen={isFullScreen}
      PaperProps={paperProps}
    >
      {open && (
        <Box
          p={noPadding ? 0 : 24}
          h={isFullScreen ? '100%' : undefined}
          bgcolor={bgcolor}
        >
          {!hideCloseIcon && (
            <Box absolute top={14} right={14}>
              <IconButton
                onClick={handleCloseClick}
                icon="Close"
                size="small"
                dataTestId="FDBDialog-close-icon"
              />
            </Box>
          )}
          {title && (
            <Box mt={32} mb={24}>
              <Text id={titleId} variant="heading2">
                {title}
              </Text>
            </Box>
          )}
          <div id={descriptionId}>{children(onClose)}</div>
          {actionButtonsProps.buttons && (
            <Box mt={24}>
              <ActionButtons {...actionButtonsProps} />
            </Box>
          )}
        </Box>
      )}
    </MUIDialog>
  );
};

export default withCx<DialogProps>('FDBDialog')(Dialog);
