import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import BrowserHistory from 'initializers/BrowserHistory';
import CommandBar from 'initializers/CommandBar';

import BottomDrawerRoot from 'features/bottomDrawers';
import HiringFeedbackModal from 'features/hiring/ftu/HiringFeedbackModal';
import Success from 'features/signUp/components/Success/Success';
import { ROUTES } from 'features/signUp/constants';

import Loader from 'components/Loader';

import { browserHistory } from 'util/router';

// USED IN MULTIPLE ROUTES
const LazyHiringDashboardView = lazy(() =>
  import(
    /* webpackPreload: true, webpackChunkName: "hiring__dashboard" */ '../features/hiring/dashboard/DashboardView'
  )
);
const LazyReportsView = lazy(() =>
  import(
    /* webpackPreload: true, webpackChunkName: "reports" */ '../features/reports/ReportsView'
  )
);
const LazySettingsView = lazy(() =>
  import(
    /* webpackPreload: true, webpackChunkName: "settings" */ '../features/settings/SettingsView'
  )
);
const LazyRefreshComponent = lazy(() =>
  import(
    /* webpackPreload: true, webpackChunkName: "refresh" */ '../features/refreshComponent'
  )
);
const LazyCloverSignUpView = lazy(() =>
  import(
    /* webpackPreload: true, webpackChunkName: "clover__sign_up" */ '../features/signUp/clover/SignUpView'
  )
);
const LazySignInView = lazy(() =>
  import(
    /* webpackPreload: true, webpackChunkName: "authentication__sign_in" */ '../features/authentication/SignIn'
  )
);

const ROUTE_STYLE = { height: '100%' };

const Routes = () => (
  <Router history={browserHistory}>
    <BrowserHistory>
      <LastLocationProvider>
        <BottomDrawerRoot />
        <HiringFeedbackModal />
        {/*
          Ensure any new wrappers placed around this top-level div are either fragments,
          or are themselves divs with 100% height
          */}
        <div style={ROUTE_STYLE}>
          <Suspense fallback={<Loader isLoading centered />}>
            <CommandBar>
              <Route
                exact
                path="/jobs/sign_in"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "hiring__sign_in" */ 'features/hiring/signIn/SignInView'
                  )
                )}
              />
              <Route
                exact
                path="/accounts/sign-in"
                component={LazySignInView}
              />
              <Route
                exact
                path="/locations/match/list"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "locations__match_view" */ 'features/locations/MatchView'
                  )
                )}
              />
              <Switch>
                <Route
                  exact
                  path="/team"
                  component={lazy(() =>
                    import(
                      /* webpackPreload: true, webpackChunkName: "team" */ '../features/teamView'
                    )
                  )}
                />
                <Route
                  exact
                  path="/user_documents/tax_documents"
                  component={lazy(() =>
                    import(
                      /* webpackPreload: true, webpackChunkName: "user_documents__tax_documents" */ '../features/user_documents/TaxDocumentsView'
                    )
                  )}
                />
                <Route
                  exact
                  path="/user_documents/pay_statements"
                  component={lazy(() =>
                    import(
                      /* webpackPreload: true, webpackChunkName: "user_documents__paystubs" */ '../features/user_documents/PayStatementsView'
                    )
                  )}
                />
                <Route
                  exact
                  path="/team/tax_forms"
                  component={lazy(() =>
                    import(
                      /* webpackPreload: true, webpackChunkName: "team__tax_forms" */ '../features/payroll/TeamTaxFormsView'
                    )
                  )}
                />
                <Route
                  exact
                  path="/team/import"
                  component={lazy(() =>
                    import(
                      /* webpackPreload: true, webpackChunkName: "team__import" */ '../features/importEmployees/ImportEmployeesView'
                    )
                  )}
                />
                <Route
                  exact
                  path="/roles/import"
                  component={lazy(() =>
                    import(
                      /* webpackPreload: true, webpackChunkName: "roles__import" */ '../features/importRoles/ImportRolesView'
                    )
                  )}
                />
                <Route
                  path="/team/:userId"
                  component={lazy(() =>
                    import(
                      /* webpackPreload: true, webpackChunkName: "team__profile" */ '../features/employeeProfile'
                    )
                  )}
                />
              </Switch>
              <Switch>
                <Route exact path="/settings" component={LazySettingsView} />
                <Route path="/settings/:page" component={LazySettingsView} />
              </Switch>
              <Switch>
                <Route exact path="/reports" component={LazyReportsView} />
                <Route path="/reports/:page" component={LazyReportsView} />
              </Switch>
              <Switch>
                <Route
                  exact
                  path="/hiring/dashboard"
                  component={LazyHiringDashboardView}
                />
                <Route
                  exact
                  path="/hiring/dashboard/new"
                  component={LazyHiringDashboardView}
                />
                <Route
                  exact
                  path="/hiring/job_post/new"
                  component={lazy(() =>
                    import(
                      /* webpackPreload: true, webpackChunkName: "hiring__new_job_post" */ '../features/hiring/jobPost/JobPost'
                    )
                  )}
                />
                <Route
                  exact
                  path="/hiring/job_post/:jobRequestId/edit"
                  component={lazy(() =>
                    import(
                      /* webpackPreload: true, webpackChunkName: "hiring__job_post_edit" */ '../features/hiring/jobPost/JobPost'
                    )
                  )}
                />
                <Route
                  exact
                  path="/hiring/job_post/:jobRequestId/success"
                  component={lazy(() =>
                    import(
                      /* webpackPreload: true, webpackChunkName: "hiring__job_post_success" */ '../features/hiring/jobPost/JobPostSuccessView/Router'
                    )
                  )}
                />
                <Route
                  exact
                  path="/hiring/interviews"
                  component={lazy(() =>
                    import(
                      /* webpackPreload: true, webpackChunkName: "hiring__interviews" */
                      '../features/hiring/interviews/InterviewsView/InterviewsView'
                    )
                  )}
                />
                <Route
                  path="/hiring/:ownerType/:ownerId/manage_applicants/:applicantToken?"
                  component={lazy(() =>
                    import(
                      /* webpackPreload: true, webpackChunkName: "hiring__manage_applicants_view" */
                      '../features/hiring/manageApplicants/ManageApplicantsView'
                    )
                  )}
                />
                <Route
                  path="/hiring/manage_applicants"
                  component={lazy(() =>
                    import(
                      /* webpackPreload: true, webpackChunkName: "hiring__manage_applicants_router" */ '../features/hiring/manageApplicants/Router'
                    )
                  )}
                />
              </Switch>
              <Route
                path="/jobs/profile"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "hiring__jobs_profile" */
                    '../features/hiring/applicantProfile/ApplicantProfileView'
                  )
                )}
              />
              <Route
                path="/cmp/:id"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "hiring__company_profile_view" */ '../features/hiring/companyProfile/CompanyProfileView'
                  )
                )}
              />
              <Route
                path="/loc/:locationProfileId/job/:jobRequestId"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "hiring__job_request_view" */ '../features/hiring/jobRequest/JobRequestView'
                  )
                )}
              />
              <Route
                exact
                path="/go_paperless_success"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "payroll__go_paperless_success_view" */ '../features/payroll/GoPaperless/Success'
                  )
                )}
              />
              <Route
                exact
                path="/go_paperless_failure"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "payroll__go_paperless_failure_view" */ '../features/payroll/GoPaperless/Failure'
                  )
                )}
              />
              <Route
                exact
                path="/request-call/:token"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "monetization_welcome_text_request_callback" */ '../features/monetization/WelcomeText/RequestCallback'
                  )
                )}
              />
              <Route
                path="/dashboard/location"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "dashboard__location" */ '../features/dashboard/DashboardView'
                  )
                )}
              />
              <Route
                path="/web_timeclock"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "web_timeclock" */ '../features/timeClock/TimeClockView'
                  )
                )}
              />
              <Route
                path="/schedule_builder_beta"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "schedule_builder" */ '../features/scheduleBuilder/ScheduleBuilderView'
                  )
                )}
              />
              <Switch>
                <Route
                  path="/timesheets/payroll_summary_detail"
                  component={lazy(() =>
                    import(
                      /* webpackPreload: true, webpackChunkName: "timesheets__payroll_summary_detail" */ '../features/timesheets/PayrollSummariesView/PayrollSummaryDetailView'
                    )
                  )}
                />
                <Route
                  path="/timesheets/payroll_summaries"
                  component={lazy(() =>
                    import(
                      /* webpackPreload: true, webpackChunkName: "timesheets__payroll_summaries" */ '../features/timesheets/PayrollSummariesView'
                    )
                  )}
                />
                <Route
                  matchRoutes
                  path="/timesheets/daily_review"
                  component={lazy(() =>
                    import(
                      /* webpackPreload: true, webpackChunkName: "timesheets" */ '../features/timesheets/TimesheetsPage/TimesheetsPage'
                    )
                  )}
                />
                <Route
                  matchRoutes
                  path="/timesheets/pay_period_review"
                  component={lazy(() =>
                    import(
                      /* webpackPreload: true, webpackChunkName: "timesheets" */ '../features/timesheets/TimesheetsPage/TimesheetsPage'
                    )
                  )}
                />
                <Route
                  matchRoutes
                  path="/timesheets"
                  component={lazy(() =>
                    import(
                      /* webpackPreload: true, webpackChunkName: "timesheets" */ '../features/timesheets/TimesheetsPage/TimesheetsPage'
                    )
                  )}
                />
              </Switch>
              <Switch>
                <Route
                  exact
                  path="/tiers/biller/downgrade"
                  component={lazy(() =>
                    import(
                      /* webpackPreload: true, webpackChunkName: "tiers__downgrade" */ '../features/biller/DowngradeToBasicTierView'
                    )
                  )}
                />
              </Switch>
              <Route
                path="/departments"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "departments" */ '../features/departments/DepartmentsView'
                  )
                )}
              />
              <Switch>
                <Route
                  exact
                  path="/time_offs/history"
                  component={lazy(() =>
                    import(
                      /* webpackPreload: true, webpackChunkName: "time_off__history" */ '../features/timeOff/TimeOffHistoryView'
                    )
                  )}
                />
                <Route
                  path="/time_offs"
                  component={lazy(() =>
                    import(
                      /* webpackPreload: true, webpackChunkName: "time_off" */ '../features/timeOff/TimeOffView'
                    )
                  )}
                />
              </Switch>
              <Switch>
                <Route
                  exact
                  path="/pto_policies/new"
                  component={lazy(() =>
                    import(
                      /* webpackPreload: true, webpackChunkName: "pto_policy__new_step_form" */ '../features/ptoPolicy/PTOPolicyStepForm'
                    )
                  )}
                />
                <Route
                  exact
                  path="/pto_policies/:policyId/edit"
                  component={lazy(() =>
                    import(
                      /* webpackPreload: true, webpackChunkName: "pto_policy__edit_step_form" */ '../features/ptoPolicy/PTOPolicyStepForm'
                    )
                  )}
                />
                <Route
                  path="/pto_policies/:policyId"
                  component={lazy(() =>
                    import(
                      /* webpackPreload: true, webpackChunkName: "pto_policy" */ '../features/ptoPolicy/PTOPolicyView'
                    )
                  )}
                />
              </Switch>
              <Route
                path="/referrals"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "referrals" */ '../features/referrals/UserReferralsContainer'
                  )
                )}
              />
              <Route
                path="/onboarding/questions"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "signup__onboarding_questions" */ '../features/signUp/components/OnboardingQuestionsView'
                  )
                )}
              />
              <Route exact path={`${ROUTES.QSG_SPLASH}?confirmation_flow=true`}>
                <Success confirmationFlow />
              </Route>
              <Route
                path="/onboarding/sign-up"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "signup" */ '../features/signUp/Root'
                  )
                )}
              />
              <Route
                path="/onboarding/employee_choice"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "signup__employee_choice_step" */ '../features/signUp/components/EmployeeChoiceStep'
                  )
                )}
              />
              <Route
                path="/onboarding/employee_get_app"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "signup__employee_get_app_view" */ '../features/signUp/components/EmployeeGetAppView'
                  )
                )}
              />
              <Switch>
                <Route
                  exact
                  path="/clover/sign-up"
                  component={LazyCloverSignUpView}
                />
                <Route
                  path="/clover/sign-up/:page"
                  component={LazyCloverSignUpView}
                />
              </Switch>
              <Route path="/security/authentication_tokens/new">
                <Redirect to="/security/authentication-tokens/new" />
              </Route>
              <Route
                path="/security/authentication-tokens/new"
                component={LazySignInView}
              />
              <Switch>
                <Route
                  path="/timeclock/health_answers"
                  component={lazy(() =>
                    import(
                      /* webpackPreload: true, webpackChunkName: "timeclock__health_answers_view" */ '../features/timeClock/HealthAnswersReportView'
                    )
                  )}
                />

                <Route
                  path="/timeclock/health_questions"
                  component={lazy(() =>
                    import(
                      /* webpackPreload: true, webpackChunkName: "timeclock__health_questions_view" */ '../features/timeClock/HealthQuestionsView'
                    )
                  )}
                />
              </Switch>
              <Route
                path="/admin_map"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "admin__map_view" */ '../features/admin/map/MapView'
                  )
                )}
              />
              <Route
                path="/admin"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "admin" */ '../features/admin/AdminView'
                  )
                )}
              />
              <Route
                path="/schedule_builder/overview"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "schedule_builder__overview" */ 'features/scheduleBuilder/OverviewVideoView'
                  )
                )}
              />
              <Route
                path="/hr_pro"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "hr_pro" */ '../features/hrPro/HrProView'
                  )
                )}
              />
              <Route
                path="/money"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "cash_out__money" */ '../features/shiftPay/ShiftPayMarketingView'
                  )
                )}
              />
              {/* Deprecated in favor of /money route */}
              <Route
                path="/cash_out"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "cash_out" */ '../features/shiftPay/ShiftPayMarketingView'
                  )
                )}
              />
              {/* Deprecated in favor of /money route */}
              <Route
                path="/instant-pay"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "cash_out__instant_pay" */ '../features/shiftPay/ShiftPayMarketingView'
                  )
                )}
              />
              <Route path="/mfa/challenge" component={LazySignInView} />
              <Route
                path="/authentication/delegated-verification/validate-code"
                component={LazySignInView}
              />
              {/* Multi-wage route */}
              <Route
                path="/payroll/runs"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "payroll__runs" */ '../features/payroll/PayrollRunView/Standard'
                  )
                )}
              />
              <Route
                path="/payroll/tax_records"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "payroll__tax_returns" */ '../features/payroll/TaxReturnsView'
                  )
                )}
              />
              <Route
                path="/payroll"
                exact
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "payroll" */ '../features/payroll/PayrollController'
                  )
                )}
              />
              <Route
                path="/payroll-terms"
                exact
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "payroll__terms" */ '../features/payroll/PayrollTermsView/PayrollTermsView'
                  )
                )}
              />
              <Switch>
                <Route
                  path="/payroll/setup/implementation"
                  exact
                  component={lazy(() =>
                    import(
                      /* webpackPreload: true, webpackChunkName: "payroll__self_serve_setup_dashboard" */ '../features/payroll/SelfServeSetupView/TasksDashboard/TasksDashboardView'
                    )
                  )}
                />
                <Route
                  path="/payroll/setup/start"
                  exact
                  component={lazy(() =>
                    import(
                      /* webpackPreload: true, webpackChunkName: "payroll" */ '../features/payroll/PayrollController'
                    )
                  )}
                />
                <Route
                  path="/payroll/setup/:step"
                  exact
                  component={lazy(() =>
                    import(
                      /* webpackPreload: true, webpackChunkName: "payroll__self_serve_setup_linear_flow" */ '../features/payroll/SelfServeSetupView/LinearFlow/LinearFlowView'
                    )
                  )}
                />
              </Switch>
              <Route
                path="/employee_onboarding/single_document_packet/:packetId"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "employee_onboarding__single_document_packet" */ '../features/employeeOnboarding/packet/singleDocument/Root'
                  )
                )}
              />
              <Route
                path="/employee_onboarding/packet/:packetId"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "employee_onboarding__packet" */ '../features/employeeOnboarding/packet/Root'
                  )
                )}
              />
              <Route
                path="/new_hire_onboarding"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "employee_onboarding__new_hire" */ '../features/employeeOnboarding/newHire/NewHireOnboardingView'
                  )
                )}
              />
              <Route
                path="/employee_onboarding/custom_documents/new"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "employee_onboarding__documents" */ '../features/employeeOnboarding/newHire/customDocumentEditor/UploadCustomDocument'
                  )
                )}
              />
              <Route
                path="/employee_onboarding/custom_documents/:customDocumentId/edit"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "employee_onboarding__documents" */ '../features/employeeOnboarding/newHire/customDocumentEditor/EditCustomDocumentEditor'
                  )
                )}
              />
              <Route
                path="/documents"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "employee_onboarding__documents" */ '../features/employeeOnboarding/documents/DocumentsView'
                  )
                )}
              />
              {/* /payroll/off_cycle/:id should always be first the in path array */}
              <Route
                path={['/payroll/off_cycle/:id', '/payroll/off_cycle']}
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "payroll__runs_off_cycle" */ '../features/payroll/PayrollRunView/OffCycle'
                  )
                )}
              />
              <Route
                path="/team_announcements/acknowledged"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "team_announcements__acknowledged" */ '../features/teamAnnouncements/AcknowledgedView'
                  )
                )}
              />
              <Route
                path="/help"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "help" */ '../features/help/HelpView'
                  )
                )}
              />
              <Route
                path="/hub_time_clock"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "hub_time_clock" */ '../features/hubTimeClock'
                  )
                )}
              />
              <Route
                path="/pos/shopify/time_clock"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "shopify_time_clock" */ 'features/shopifyTimeClock/ShopifyShimForHubTimeClockView'
                  )
                )}
              />
              <Route
                exact
                path="/quick_start_guide"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "onboarding__quick_start_guide" */ 'features/onboarding/QuickStartGuide'
                  )
                )}
              />
              <Route
                path="/quick_start_guide/focus"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "onboarding__quick_start_guide_focused_qsg" */ 'features/onboarding/QuickStartGuide/FocusedQSG'
                  )
                )}
              />
              <Route
                path="/quick_start_guide/splash"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "onboarding__quick_start_guide_splash" */ 'features/onboarding/LandingPage'
                  )
                )}
              />
              <Route
                path="/onboarding/:page"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "onboarding__post_sign_up" */ 'features/earlyLife/SignUpExtendedQuestions/ExtendedQuestionsView'
                  )
                )}
              />
              <Route
                exact
                path="/manage_plans"
                component={lazy(() =>
                  import('features/managePlan/ManagePlanView')
                )}
              />
              {/* These are placeholders for an empty React component that forces a hard refresh.
            This is to enable route blocking via history.block(). Should be replaced/removed if
            these views are ever ported to React.
            https://github.com/remix-run/history/blob/dev/docs/blocking-transitions.md */}
              <Route
                exact
                path="/schedule_builder"
                component={LazyRefreshComponent}
              />
              <Route
                exact
                path="/manager_log"
                component={LazyRefreshComponent}
              />
              <Route exact path="/profile" component={LazyRefreshComponent} />
              <Route
                exact
                path="/availabilities"
                component={LazyRefreshComponent}
              />
              <Route
                exact
                path="/user/availability"
                component={LazyRefreshComponent}
              />
              <Route
                exact
                path="/company_timesheets"
                component={LazyRefreshComponent}
              />
              <Route
                exact
                path="/home"
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "home" */ 'features/refreshComponent/NavLogoRefreshComponent'
                  )
                )}
              />
              <Route
                path="/growth/launch_week/award_mobile_share"
                exact
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "growth__launch_week__award_mobile_share" */ '../features/Growth/LaunchWeek/LaunchWeekAwardMobileShare'
                  )
                )}
              />
              <Route
                path="/hb1/aHR0cHM6Ly9"
                exact
                component={lazy(() =>
                  import(
                    /* webpackPreload: true, webpackChunkName: "growth__launch_week__award_mobile_share" */ '../features/Growth/LaunchWeek/LaunchWeekAwardMobileShare'
                  )
                )}
              />
            </CommandBar>
          </Suspense>
        </div>
      </LastLocationProvider>
    </BrowserHistory>
  </Router>
);

export default Routes;
