import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { fetchJSON } from 'api/fetch';
import CheckMark from 'fe-design-base/assets/illustrations/CheckMark';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import TextInput from 'fe-design-base/atoms/TextInput';
import Button from 'fe-design-base/molecules/Button';
import Dialog from 'fe-design-base/organisms/Dialog';

import {
  getCurrentLocationId,
  getCurrentLocationLaunchWeekSettings,
  getCurrentUserId,
} from 'selectors/session';

import { createMessage } from 'features/messengerv2/actions';
import { getLocationParticipantsApiRoute } from 'features/messengerv2/apiRoutes';
import {
  ALL_MANAGERS,
  CELEBRATION,
  ENTIRE_TEAM,
} from 'features/messengerv2/constants';

import { cxHelpers } from 'util/className';
import { datadogLogError } from 'util/datadogLogs';
import { error as flashError } from 'util/flashNotice';
import { toI18n } from 'util/i18n';
import { EVENT_ACTIONS, TRACK_ACTION_TYPES } from 'util/tracking_constants';

const { cx } = cxHelpers('LaunchWeekShareWithTeamDialog');

const LaunchWeekShareWithTeamDialog = ({
  currentLocationId,
  i18n,
  launchWeekSettings,
  onClose,
  onSendMessage,
  open,
  trackUx,
}) => {
  const [channelsList, setChannelsList] = useState([]);

  useEffect(() => {
    fetchJSON(
      // We use {perPage: 1} because we only need the quick access channels returned
      // from that endpoint, and that is independent from the number of participants.
      getLocationParticipantsApiRoute({
        page: 1,
        perPage: 1,
        locationId: currentLocationId,
      })
    )
      .then(response => {
        setChannelsList(response.quick_access);
      })
      .catch(error => {
        datadogLogError({
          message: `Launch Week > Error fetching Messaging Fixed Channels: ${error}`,
          context: { currentLocationId },
        });
        flashError(toI18n('errors.generic'));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [messageSent, setMessageSent] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);

  const awardInstagramPageUrl = launchWeekSettings.get(
    'award_mobile_share_url'
  );

  const [message, setMessage] = useState(
    toI18n(
      'growth.launch_week.sharing.dialog.share_with_team.initial_message',
      {
        props: { awardInstagramPageUrl },
      }
    )
  );

  const handleOnMessageChange = useCallback(e => {
    setMessage(e.target.value || '');
  }, []);

  const sendMessage = useCallback(
    channelId => {
      onSendMessage({
        message: { message: { attachment_attributes: null, body: message } },
        channelId,
      })
        .then(() => {
          trackUx(EVENT_ACTIONS.BUTTON_CLICKED, TRACK_ACTION_TYPES.CLICK, {
            share_type: 'message_your_team',
            element: 'send',
          });
          setMessageSent(true);
        })
        .catch(() => {
          setIsSendingMessage(false);
          flashError(toI18n('errors.generic'));
        });
    },
    [message, onSendMessage, trackUx]
  );

  const handleOnClose = useCallback(() => {
    setIsSendingMessage(false);
    setMessageSent(false);
    onClose();
  }, [onClose]);

  const handleMessageSent = useCallback(() => {
    trackUx(EVENT_ACTIONS.BUTTON_CLICKED, TRACK_ACTION_TYPES.CLICK, {
      share_type: 'message_your_team',
      element: 'done',
    });
    handleOnClose();
  }, [handleOnClose, trackUx]);

  const handleSendTeamMessage = useCallback(() => {
    setIsSendingMessage(true);

    const validFixedChannel = channelsList?.find(
      channel =>
        channel.name === CELEBRATION ||
        channel.name === ENTIRE_TEAM ||
        channel.name === ALL_MANAGERS
    );

    if (validFixedChannel?.id) {
      sendMessage(validFixedChannel.id);
    } else {
      setIsSendingMessage(false);
      flashError(toI18n('errors.generic'));
    }
  }, [channelsList, sendMessage]);

  const SendMessageContent = useMemo(
    () => (
      <>
        <Box row hcenter vcenter>
          <Text variant="heading2" color="mono900">
            {i18n.share_with_team.title}
          </Text>
        </Box>
        <Box column>
          <Box mt={32} hleft vcenter>
            <Text variant="body" color="mono900">
              {i18n.share_with_team.subtitle}
            </Text>
          </Box>
          <Box mt={28} hleft vcenter bw={2} bradiusm bcolor="purple700">
            <TextInput
              textArea
              textAreaHeight={260}
              autoFocus
              placeholder={i18n.share_with_team.placeholder}
              value={message}
              onChange={handleOnMessageChange}
            />
          </Box>
          <Box hright mt={36}>
            <Button
              onClick={handleSendTeamMessage}
              variant="primary"
              isLoading={isSendingMessage}
            >
              {i18n.share_with_team.cta}
            </Button>
          </Box>
        </Box>
      </>
    ),
    [
      handleOnMessageChange,
      handleSendTeamMessage,
      i18n,
      isSendingMessage,
      message,
    ]
  );

  const MessageSentContent = useMemo(
    () => (
      <>
        <Box column vcenter hcenter tcenter>
          <Text variant="heading2" color="mono900">
            {i18n.share_with_team.title}
          </Text>
          <Box mt={42} hcenter vcenter>
            <CheckMark w={250} h={250} />
          </Box>
          <Box>
            <Text variant="heading2" color="mono900">
              {i18n.share_with_team.sent}
            </Text>
          </Box>
          <Box mt={72}>
            <Button onClick={handleMessageSent} variant="primary">
              {i18n.share_with_team.next}
            </Button>
          </Box>
        </Box>
      </>
    ),
    [handleMessageSent, i18n]
  );

  return (
    <Dialog
      className={cx()}
      disableBackdropClick
      noPadding
      onClose={handleOnClose}
      open={open}
    >
      {() => (
        <Box className={cx()} pl={24} pr={24} pt={16} pb={16} w={580} h={485}>
          {messageSent ? MessageSentContent : SendMessageContent}
        </Box>
      )}
    </Dialog>
  );
};

export default connect(
  state => ({
    launchWeekSettings: getCurrentLocationLaunchWeekSettings(state),
    currentLocationId: getCurrentLocationId(state),
    currentUserId: getCurrentUserId(state),
  }),
  {
    onSendMessage: createMessage,
  }
)(LaunchWeekShareWithTeamDialog);
