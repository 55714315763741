import React, { useCallback, useEffect, useState } from 'react';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { StripeElements } from '@stripe/stripe-js';
import { postJSON } from 'api/fetch';
import Box from 'fe-design-base/atoms/Box';
import RadioButton from 'fe-design-base/atoms/RadioButton';
import Text from 'fe-design-base/atoms/Text';
import Alert from 'fe-design-base/molecules/Alert';
import { List } from 'immutable';

import {
  subscribeToOnConfirmAndPay,
  unsubscribeFromOnConfirmAndPay,
} from 'features/monetization/StripeCheckoutDrawer/events';

import { cxHelpers } from 'util/className';

import { PaymentMethodModuleCreditCardPicker } from './PaymentMethodModuleCreditCardPicker';
import PriceSummary from './PriceSummary';
const { cx } = cxHelpers('PurchaseMethodModuleView');
const PaymentMethodOption = {
  newCard: 'newCard',
  existingCard: 'existingCard',
};

interface ConfirmPaymentParams {
  clientSecret: string;
  redirect: string;
  elements?: StripeElements;
  confirmParams?: Record<string, unknown>;
}
export interface Price {
  amount: number;
  priceSystemId: string;
}
export interface PurchaseMethodModuleViewProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  creditCards: List<any>;
  onFetchCreditCards: (...args: unknown[]) => void;
  onSuccess: () => void;
  onLoading: (isLoading: boolean) => void;
  price: Price;
  salesTax: number;
}

export const PurchaseMethodModuleView = ({
  creditCards,
  onFetchCreditCards,
  onSuccess,
  onLoading,
  price,
  salesTax,
}: PurchaseMethodModuleViewProps) => {
  const hasCreditCards = creditCards.size > 0;
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(
    hasCreditCards ? creditCards.first().get('payment_method_id') : null
  );
  const [currentPaymentMethodOption, setCurrentPaymentMethodOption] = useState(
    hasCreditCards
      ? PaymentMethodOption.existingCard
      : PaymentMethodOption.newCard
  );

  const handleOnChangePaymentMethodOption = useCallback(e => {
    setCurrentPaymentMethodOption(e.target.name);
  }, []);

  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState<string>();
  const createProductSubscription = useCallback(async () => {
    if (!stripe || !elements) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-shadow,@typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { error: submitError } = await elements.submit();
    if (submitError) {
      onLoading(false);
      setErrorMessage(submitError);
      return;
    }

    try {
      const result = await postJSON('/biller/product_subscriptions/subscribe', {
        payment_method_id:
          currentPaymentMethodOption === PaymentMethodOption.existingCard
            ? selectedPaymentMethodId
            : null,
        price_system_id: price.priceSystemId,
      });

      let params: ConfirmPaymentParams = {
        clientSecret: result.client_secret,
        confirmParams: {
          return_url: window.location.href + '?success=true',
        },
        redirect: 'if_required',
      };

      if (currentPaymentMethodOption === PaymentMethodOption.newCard) {
        params = { ...params, elements };
      }

      // eslint-disable-next-line @typescript-eslint/no-shadow,@typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { error } = await stripe.confirmPayment(params);

      if (error) {
        onLoading(false);
        setErrorMessage(error);
        return;
      }

      onSuccess();
    } catch (error) {
      let message = 'An error occurred. Please try again later.';

      if (error instanceof Error) {
        message = error.message;
      }
      onLoading(false);
      setErrorMessage(message);
    }

    // TODO: Call backend api to confirm payment
  }, [
    currentPaymentMethodOption,
    elements,
    onLoading,
    onSuccess,
    price.priceSystemId,
    selectedPaymentMethodId,
    stripe,
  ]);

  useEffect(() => {
    if (stripe && elements) {
      subscribeToOnConfirmAndPay(createProductSubscription);
    }

    return () => {
      unsubscribeFromOnConfirmAndPay(createProductSubscription);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe, elements, createProductSubscription]);

  useEffect(() => {
    onFetchCreditCards();
  }, [onFetchCreditCards]);

  return (
    <Box className={cx()}>
      <Box mt={32}>
        {errorMessage && <Alert variant="error">{errorMessage}</Alert>}
        <Text
          variant="heading3"
          i18n="biller.purchase_method_module.payment_method"
        />
      </Box>
      {hasCreditCards && (
        <Box mt={24}>
          <Box row vtop maxw={340} gap={8}>
            <RadioButton
              uxElement="biller.payment_method_module.existing_card_radio_button"
              name={PaymentMethodOption.existingCard}
              onChange={handleOnChangePaymentMethodOption}
              checked={
                currentPaymentMethodOption === PaymentMethodOption.existingCard
              }
            />
            <Box pt={4} gap={18} column grow={1}>
              <Text
                variant="body"
                color="mono900"
                i18n="biller.change_payment_method.label_select_card"
              />
              {currentPaymentMethodOption ===
                PaymentMethodOption.existingCard && (
                <PaymentMethodModuleCreditCardPicker
                  creditCards={creditCards}
                  value={selectedPaymentMethodId}
                  onChange={setSelectedPaymentMethodId}
                />
              )}
            </Box>
          </Box>

          <Box row vcenter mt={18} gap={8}>
            <RadioButton
              uxElement="biller.payment_method_module.enter_new_credit_card_radio_button"
              name={PaymentMethodOption.newCard}
              onChange={handleOnChangePaymentMethodOption}
              checked={
                currentPaymentMethodOption === PaymentMethodOption.newCard
              }
            />
            <Text
              variant="body"
              color="mono900"
              i18n="biller.change_payment_method.link_enter_new"
            />
          </Box>
        </Box>
      )}
      {(!hasCreditCards ||
        currentPaymentMethodOption === PaymentMethodOption.newCard) && (
        <Box mt={24} className={cx()}>
          <PaymentElement />
        </Box>
      )}
      <PriceSummary salesTax={salesTax} price={price.amount} />
    </Box>
  );
};
