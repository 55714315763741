import { createAsyncThunk } from '@reduxjs/toolkit';
import { pollWorkerStatus } from 'api/workerStatus';

import { ROUTES, SLICE_NAME } from 'features/signUp/clover/constants';
import { createBusinessTracking } from 'features/signUp/util/apiHelper';

import { browserHistory } from 'util/router';

import {
  fetchEmployees,
  inviteEmployees,
  postCreateCloverAccount,
  postSyncCloverEmployees,
  putOnboardingProperties,
  sendInstructions,
} from './api';

const { BUSINESS_DETAILS, PRIMER } = ROUTES;

export const createCloverAccount = createAsyncThunk(
  `${SLICE_NAME}/createAccount`,
  (_, { rejectWithValue }) =>
    postCreateCloverAccount()
      .then(params => {
        createBusinessTracking({ ...params });

        postSyncCloverEmployees({
          locationId: params.location.id,
          sendInvite: false,
          pos: true,
        })
          .then(resp => {
            pollWorkerStatus(resp.job_id)
              .catch(() => {
                sessionStorage.setItem(
                  'error',
                  'error occurred while employee sync'
                );
              })
              .finally(() => {
                browserHistory.replace(BUSINESS_DETAILS);
              });
          })
          .catch(() => {
            sessionStorage.setItem(
              'error',
              'error occurred while employee sync'
            );
            browserHistory.replace(BUSINESS_DETAILS);
          });

        return params;
      })
      .catch(error => rejectWithValue(error.body || error.message))
);

export const updateOnboardingProperties = createAsyncThunk(
  `${SLICE_NAME}/updateOnboardingProperties`,
  ({ nextScreen, usePush = false, ...payload }, { rejectWithValue }) =>
    putOnboardingProperties(payload)
      .then(() =>
        usePush
          ? browserHistory.push(nextScreen)
          : browserHistory.replace(nextScreen)
      )
      .catch(error => rejectWithValue(error.body || error.message))
);

export const fetchCloverEmployees = createAsyncThunk(
  `${SLICE_NAME}/fetchEmployees`,
  (_, { rejectWithValue }) =>
    fetchEmployees().catch(error =>
      rejectWithValue(error.body || error.message)
    )
);

export const sendInviteEmployees = createAsyncThunk(
  `${SLICE_NAME}/sendInviteEmployees`,
  (_, { rejectWithValue }) =>
    inviteEmployees()
      .then(() => browserHistory.replace(PRIMER))
      .catch(error => rejectWithValue(error.body || error.message))
);

export const sendPOSInstructions = createAsyncThunk(
  `${SLICE_NAME}/sendInstructions`,
  (_, { rejectWithValue }) =>
    sendInstructions().catch(error =>
      rejectWithValue(error.body || error.message)
    )
);
