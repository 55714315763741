import { MODULE_NAME } from '../../constants';

import { SLICE_NAME } from './constants';

export const selectLocationId = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'locationId']);
export const selectCompanyId = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'companyId']);

export const getCreditCards = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'creditCards']);
export const getCreditCardsError = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'creditCardsError']);

export const getChangePaymentMethodPending = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'changePaymentMethodPending']);
export const getChangePaymentMethodError = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'creditCardsError']);
export const getUpdatePaymentMethodPending = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'updatePaymentMethodPending']);
export const getUpdatePaymentMethodError = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'updatePaymentMethodErrors']);

export const selectModuleUpdatePaymentMethodPending = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'moduleUpdatePaymentMethodPending']);
export const selectModuleUpdatePaymentMethodError = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'moduleUpdatePaymentMethodErrors']);

export const getAddHiringBoostPending = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'addHiringBoostPending']);
export const getAddHiringBoostError = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'creditCardsError']);
export const getCreateHiringBoostPending = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'createHiringBoostPending']);
export const getCreateHiringBoostErrors = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'createHiringBoostErrors']);

export const selectSetupIntentPending = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'setupIntentPending']);
export const selectSetupIntentSecret = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'setupIntentSecret']);
export const selectSetupIntentStatus = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'setupIntentStatus']);
export const selectSetupIntentErrors = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'setupIntentErrors']);

export const selectPaymentIntentPending = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'paymentIntentPending']);
export const selectPaymentIntentSecret = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'paymentIntentSecret']);
export const selectPaymentIntentErrors = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'paymentIntentErrors']);
