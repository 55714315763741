export const MODAL = 'modal';

export const READONLY_MODAL_TYPE = 'READONLY';
export const CONFIRM_MODAL_TYPE = 'CONFIRM';
export const CONFIRM_WITH_SECONDARY_MODAL_TYPE =
  'CONFIRM_WITH_SECONDARY_MODAL_TYPE';
export const DISMISS_ONLY_MODAL_TYPE = 'DISMISS_ONLY_MODAL_TYPE';
export const EXIT_WITHOUT_SAVING_MODAL_TYPE = 'EXIT_WITHOUT_SAVING_MODAL_TYPE';
export const ERROR_MODAL_TYPE = 'ERROR';
export const PAGE_TIPS_MODAL_TYPE = 'PAGE_TIPS';
export const ONBOARDING_QUESTIONS_MODAL_TYPE = 'ONBOARDING_QUESTIONS';
export const CLOVER_ONBOARDING_QUESTIONS_MODAL_TYPE =
  'CLOVER_ONBOARDING_QUESTIONS_MODAL_TYPE';
export const ICON_MODAL_TYPE = 'ICON_MODAL';
export const TOAST_POS_CONNECT_MODAL = 'TOAST_POS_CONNECT_MODAL';
export const ROOT_ONBOARDING_MODAL = 'ROOT_ONBOARDING_MODAL';
export const GET_MOBILE_APP_MODAL = 'GET_MOBILE_APP_MODAL';
export const HIRING_BOOST_UPSELL_MODAL_TYPE = 'HIRING_BOOST_UPSELLMODAL';
export const ADP_INTERRUPT_MODAL = 'ADP_INTERRUPT_MODAL';
export const NEW_LOCATION_MODAL = 'NEW_LOCATION_MODAL';
export const NEW_LOCATION_DEPARTMENT_MODAL = 'NEW_LOCATION_DEPARTMENT_MODAL';
export const SECOND_LOCATION_TRIAL_UPGRADE_MODAL =
  'SECOND_LOCATION_TRIAL_UPGRADE_MODAL';
export const SECOND_LOCATION_TRIAL_SETTINGS_MODAL =
  'SECOND_LOCATION_TRIAL_SETTINGS_MODAL';
export const MESSENGER_EVERYONE_WORKING_TODAY_EMPTY_MODAL =
  'MESSENGER_EVERYONE_WORKING_TODAY_EMPTY_MODAL';
export const CONFIRM_MODAL_ALT_TYPE = 'CONFIRM_MODAL_ALT_TYPE';
export const MONETIZATION_RETRIAL_MODAL = 'MONETIZATION_RETRIAL_MODAL';
export const TIMECARDS_COMPLIANCE_UPSELL_MODAL =
  'TIMECARDS_COMPLIANCE_UPSELL_MODAL';
export const RETRIAL_HAS_ENDED_MODAL = 'RETRIAL_HAS_ENDED_MODAL';
export const DOWNGRADE_MANAGERS_MODAL = 'DOWNGRADE_MANAGERS_MODAL';
export const ONBOARDING_EMPLOYEES_STEP_MODAL =
  'ONBOARDING_EMPLOYEES_STEP_MODAL';
export const ROOT_RETRIAL_ONBOARDING_MODAL = 'ROOT_RETRIAL_ONBOARDING_MODAL';
export const ROOT_MFA_ENABLE_MODAL = 'ROOT_MFA_ENABLE_MODAL';
export const ROOT_MFA_EDIT_MODAL = 'ROOT_MFA_EDIT_MODAL';
export const ROOT_MFA_MODAL = 'ROOT_MFA_MODAL';
export const AUTO_SCHEDULING_GUIDELINES_MODAL =
  'AUTO_SCHEDULING_GUIDELINES_MODAL';
export const USER_WEB_TIMECLOCK_MODAL = 'USER_WEB_TIMECLOCK_MODAL';
export const CHOOSE_TIME_CLOCK_MODAL = 'CHOOSE_TIME_CLOCK_MODAL';
export const PAY_BASE_MODAL = 'PAY_BASE_MODAL';
export const PREMIUM_BOOSTS_NOT_AVAILABLE_MODAL =
  'PREMIUM_BOOSTS_NOT_AVAILABLE_MODAL';
export const HIGHLIGHT_CLOCK_IN_NAV_ITEM = 'HIGHLIGHT_CLOCK_IN_NAV_ITEM';
export const DASHBOARD_VIDEO_MODAL = 'DASHBOARD_VIDEO_MODAL';
export const PARTNER_END_OF_INTEGRATION_MODAL =
  'PARTNER_END_OF_INTEGRATION_MODAL';
export const CANCEL_INTERVIEW_CONFIRMATION_MODAL =
  'CANCEL_INTERVIEW_CONFIRMATION_MODAL';
export const NOTICE_MODAL_TYPE = 'NOTICE_MODAL';
export const DOWNLOAD_MESSAGE_LOGS_MODAL = 'DOWNLOAD_MESSAGE_LOGS_MODAL';
export const UPLOAD_DOCUMENT_MODAL = 'UPLOAD_DOCUMENT_MODAL';
export const I9_VERIFICATION_MODAL = 'I9_VERIFICATION_MODAL';
export const RESEND_DOCUMENTS_MODAL = 'RESEND_DOCUMENTS_MODAL';
export const MISSING_CONTACT_INFORMATION_MODAL =
  'MISSING_CONTACT_INFORMATION_MODAL';
export const CHANGE_OWNERSHIP_MODAL = 'CHANGE_OWNERSHIP_MODAL';
export const CHANGE_OWNERSHIP_NON_OWNER_MODAL =
  'CHANGE_OWNERSHIP_NON_OWNER_MODAL';
export const CHANGE_OWNERSHIP_CONFIRMATION_MODAL =
  'CHANGE_OWNERSHIP_CONFIRMATION_MODAL';
export const DELETE_POLICY_CONFIRMATION_MODAL =
  'DELETE_POLICY_CONFIRMATION_MODAL';
export const PREFER_PAPER_CHECKS_CONFIRMATION_MODAL =
  'PREFER_PAPER_CHECKS_CONFIRMATION_MODAL';
export const HR_PRO_VIDEO_MODAL = 'HR_PRO_VIDEO_MODAL';
export const CLICK_TO_CALL_DIALOG = 'CLICK_TO_CALL_DIALOG';

// MOBILE INTERSTITIAL
export const MOBILE_INTERSTITIAL_MODAL_TYPE = 'MOBILE_INTERSTITIAL';

// PAYROLL
export const PAYROLL_IN_PRODUCT_LEAD_MODAL = 'PAYROLL_IN_PRODUCT_LEAD_MODAL';
export const PAYROLL_RUN_TIMECARD_MODAL = 'PAYROLL_RUN_TIMECARD_MODAL';
export const PAYROLL_RUN_OTHER_EARNINGS_MODAL =
  'PAYROLL_RUN_OTHER_EARNINGS_MODAL';
export const PAYROLL_RUN_SUBMISSION_MODAL = 'PAYROLL_RUN_SUBMISSION_MODAL';
export const ARCHIVE_CUSTOM_DOCUMENT_CONFIRMATION_MODAL =
  'ARCHIVE_CUSTOM_DOCUMENT_CONFIRMATION_MODAL';
export const PAYROLL_CONTACT_MODAL = 'PAYROLL_CONTACT_MODAL';
export const PAYROLL_CONTACT_GENERIC_MODAL = 'PAYROLL_CONTACT_GENERIC_MODAL';
export const PAYROLL_SELF_SETUP_TIMELINE_MODAL =
  'PAYROLL_SELF_SETUP_TIMELINE_MODAL';
export const PAYROLL_SELF_SETUP_DOCUMENTS_UPLOAD_MODAL =
  'PAYROLL_SELF_SETUP_DOCUMENTS_UPLOAD_MODAL';
export const PAYROLL_SELF_SETUP_DOCUMENTS_UPLOAD_SUCCESS_MODAL =
  'PAYROLL_SELF_SETUP_DOCUMENTS_UPLOAD_SUCCESS_MODAL';
export const PAYROLL_CONNECT_BANK_ACCOUNT_MODAL =
  'PAYROLL_CONNECT_BANK_ACCOUNT_MODAL';
export const PAYROLL_SELF_SETUP_FEIN_REJECTED_MODAL =
  'PAYROLL_SELF_SETUP_FEIN_REJECTED_MODAL';
export const PAYROLL_COMMITMENT_MODAL = 'PAYROLL_COMMITMENT_MODAL';
export const PAYROLL_DOCUMENT_UPLOAD_MODAL = 'PAYROLL_DOCUMENT_UPLOAD_MODAL';
export const PAYROLL_MISSED_RUN_MODAL = 'PAYROLL_MISSED_RUN_MODAL';
export const PAYROLL_REOPEN_PAYROLL_RUN_MODAL =
  'PAYROLL_REOPEN_PAYROLL_RUN_MODAL';
export const PAYROLL_REOPEN_AUTOPAYROLL_RUN_MODAL =
  'PAYROLL_REOPEN_AUTOPAYROLL_RUN_MODAL';
export const PAYROLL_RUN_LATE_PAYROLL_RUN_MODAL =
  'PAYROLL_RUN_LATE_PAYROLL_RUN_MODAL';
export const PAYROLL_PAYDAY_MODAL = 'PAYROLL_PAYDAY_MODAL';
export const PAYROLL_MISSING_DETAILS_MODAL = 'PAYROLL_MISSING_DETAILS_MODAL';
export const PAYROLL_CHECK_COMPONENT_WITH_SIGNER_TITLE_MODAL =
  'PAYROLL_CHECK_COMPONENT_WITH_SIGNER_TITLE_MODAL';
export const PAYROLL_FIRST_PAYROLL_RUN_MODAL =
  'PAYROLL_FIRST_PAYROLL_RUN_MODAL';
export const PAYROLL_EDIT_TIMESHEETS_EXIT_CONFIRMATION_MODAL =
  'PAYROLL_EDIT_TIMESHEETS_EXIT_CONFIRMATION_MODAL';
export const PAYROLL_EDIT_WAGE_RATES_EXIT_CONFIRMATION_MODAL =
  'PAYROLL_EDIT_WAGE_RATES_EXIT_CONFIRMATION_MODAL';
export const PAYROLL_TIME_OFFS_MODAL = 'PAYROLL_TIME_OFFS_MODAL';
export const READY_TO_RUN_PAYROLL_MODAL = 'READY_TO_RUN_PAYROLL_MODAL';
export const PAYROLL_NSF_RESOLUTION_MODAL = 'PAYROLL_NSF_RESOLUTION_MODAL';
export const PAYROLL_NSF_WIRE_FUNDS_MODAL = 'PAYROLL_NSF_WIRE_FUNDS_MODAL';
export const PAYROLL_NSF_REATTEMPT_WITHDRAWAL_MODAL =
  'PAYROLL_NSF_REATTEMPT_WITHDRAWAL_MODAL';
export const SETUP_PAYROLL_PERIOD_MODAL = 'SETUP_PAYROLL_PERIOD_MODAL';

export const ADD_EXISTING_USER_INVITE_MODAL = 'ADD_EXISTING_USER_INVITE_MODAL';
export const VIDEO_MODAL = 'VIDEO_MODAL';
export const UNSUPPORTED_MULTI_LOCATION_MODAL =
  'UNSUPPORTED_MULTI_LOCATION_MODAL';
export const ADDITIONAL_PAYROLL_LOCATION_MODAL =
  'ADDITIONAL_PAYROLL_LOCATION_MODAL';

export const FAILED_PAYMENTS_REFUNDED_MODAL = 'FAILED_PAYMENTS_REFUNDED_MODAL';
export const PAYROLL_NEW_LOCATION_SUCCESS_MODAL =
  'PAYROLL_NEW_LOCATION_SUCCESS_MODAL';
export const ALL_IN_ONE_INFORMATION_MODAL = 'ALL_IN_ONE_INFORMATION_MODAL';
export const SET_START_DAY_MODAL = 'SET_START_DAY_MODAL';
export const TIME_OFF_MODAL = 'TIME_OFF_MODAL';
export const SB_SHOW_COPY_AND_PASTE_INSTRUCTIONS_MODAL =
  'SB_SHOW_COPY_AND_PASTE_INSTRUCTIONS_MODAL';
export const SHIFT_CONFLICT_MODAL = 'SHIFT_CONFLICT_MODAL';
export const REQUEST_TAX_WITHHOLDING_MODAL = 'REQUEST_TAX_WITHHOLDING_MODAL';
export const INVITE_EMPLOYEE_MODAL = 'INVITE_EMPLOYEE_MODAL';
export const FREE_TRIAL_MODAL = 'FREE_TRIAL_MODAL';
export const COMPLETED_GUIDE_MODAL = 'COMPLETED_GUIDE_MODAL';
export const CONFIRMATION_MODAL = 'CONFIRMATION_MODAL';
export const SCHEDULE_BUILDER_CAROUSEL_DEMO_MODAL =
  'SCHEDULE_BUILDER_CAROUSEL_DEMO_MODAL';

// BILLER AND PAYWALLS
export const BEFORE_END_OF_TRIAL_MODAL = 'BEFORE_END_OF_TRIAL_MODAL';
export const BILLER_ADMIN_CHANGE_TIER_MODAL = 'BILLER_ADMIN_CHANGE_TIER_MODAL';
export const BILLER_CHANGE_TIER_MODAL = 'BILLER_CHANGE_TIER_MODAL';
export const BILLER_JOB_REQUEST_CREATED_MODAL =
  'BILLER_JOB_REQUEST_CREATED_MODAL';
export const BILLER_MOBILE_CHANGE_TIER_MODAL =
  'BILLER_MOBILE_CHANGE_TIER_MODAL';
export const BILLER_NEW_LOCATIONS_TIER_VIEW_MODAL =
  'BILLER_NEW_LOCATIONS_TIER_VIEW_MODAL';
export const BILLER_Q2_CHANGE_TIER_MODAL = 'BILLER_Q2_CHANGE_TIER_MODAL';
export const END_OF_TRIAL_LIST_DIALOG = 'END_OF_TRIAL_LIST_DIALOG';
export const END_OF_TRIAL_LOCATION_MODAL = 'END_OF_TRIAL_LOCATION_MODAL';
export const END_OF_TRIAL_MANAGER_MODAL = 'END_OF_TRIAL_MANAGER_MODAL';
export const END_OF_TRIAL_MODAL_1 = 'END_OF_TRIAL_MODAL_1';
export const END_OF_TRIAL_MODAL_2 = 'END_OF_TRIAL_MODAL_2';
export const END_OF_TRIAL_NBK_DIALOG = 'END_OF_TRIAL_NBK_DIALOG';
export const MID_TRIAL_MTC_MODAL = 'MID_TRIAL_MTC_MODAL';
export const ENFORCEMENT_DIALOG = 'ENFORCEMENT_DIALOG';
export const INVOICED_COMPANY_MODAL = 'INVOICED_COMPANY_MODAL';
export const MULTILOCATION_ENFORCEMENT_DIALOG =
  'MULTILOCATION_ENFORCEMENT_DIALOG';
export const NEW_LOCATIONS_MODAL = 'NEW_LOCATIONS_MODAL';
export const NEW_LOCATIONS_SUCCESS_MODAL = 'NEW_LOCATIONS_SUCCESS_MODAL';
export const SHOW_Q2_TIER_CHANGE_MODAL = 'SHOW_Q2_TIER_CHANGE_MODAL';
export const STRIPE_ADD_HIRING_BOOST_MODAL = 'STRIPE_ADD_HIRING_BOOST_MODAL';
export const TEAM_PERMISSIONS_DIALOG = 'TEAM_PERMISSIONS_DIALOG';
export const HIRING_DECLINE_REASON_MODAL = 'HIRING_DECLINE_REASON_MODAL';
export const STRIPE_CHANGE_PAYMENT_METHOD_MODAL =
  'STRIPE_CHANGE_PAYMENT_METHOD_MODAL';
export const UPDATE_LOCATION_PAYMENT_MESSAGE_MODAL =
  'UPDATE_LOCATION_PAYMENT_MESSAGE_MODAL';

export const ENFORCEMENT_DIALOG_AFTER_SIGN_IN_KEY =
  'ENFORCEMENT_DIALOG_AFTER_SIGN_IN_KEY';

export const PAYROLL_RUN_ERROR_MODAL = 'PAYROLL_RUN_ERROR_MODAL';

export const PAYROLL_CONTRACTOR_CASH_TIPS_MODAL =
  'PAYROLL_CONTRACTOR_CASH_TIPS_MODAL';
export const AUTOPAYROLL_ENABLED_MODAL = 'AUTOPAYROLL_ENABLED_MODAL';
export const AUTOPAYROLL_DISABLE_MODAL = 'AUTOPAYROLL_DISABLE_MODAL';
export const AUTOPAYROLL_FIVE_MINUTE_WARNING_MODAL =
  'AUTOPAYROLL_FIVE_MINUTE_WARNING_MODAL';
export const DELETE_AUTOPAYROLL_DRAFT_MODAL = 'DELETE_AUTOPAYROLL_DRAFT_MODAL';

export const PROMOTE_TWO_STEP_VERIFICATION_MODAL =
  'PROMOTE_TWO_STEP_VERIFICATION_MODAL';

export const EXPORT_PERMISSIONS_MODAL = 'EXPORT_PERMISSIONS_MODAL';
export const MOBILE_TIME_CLOCK_CAP_SETTINGS_DIALOG =
  'MOBILE_TIME_CLOCK_CAP_SETTINGS_DIALOG';
export const MOBILE_TIME_CLOCK_TRIAL_END_DIALOG =
  'MOBILE_TIME_CLOCK_TRIAL_END_DIALOG';
export const QUICKBOOKS_EXPORT_MODAL = 'QUICKBOOKS_EXPORT_MODAL';
