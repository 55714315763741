export { default as chalk_play } from './chalk_play.gif';
export { default as coin_bicycle } from './coin_bicycle.gif';
export { default as cukoo_clock } from './cukoo_clock.gif';
export { default as donuts_loading_1 } from './donuts_loading_1.gif';
export { default as donuts_loading_2 } from './donuts_loading_2.gif';
export { default as donuts_loading_3 } from './donuts_loading_3.gif';
export { default as flip_clock } from './flip_clock.gif';
export { default as gears } from './gears.gif';
export { default as hey_plane } from './hey_plane.gif';
export { default as hired_name_tag } from './hired_name_tag.gif';
export { default as hooray_banner } from './hooray_banner.gif';
export { default as money_clock } from './money_clock.gif';
export { default as polaroid_wave } from './polaroid_wave.gif';
export { default as shifts_calendar } from './shifts_calendar.gif';
export { default as stamp_clock } from './stamp_clock.gif';
export { default as yay_payday } from './yay_payday.gif';
