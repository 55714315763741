import React, { useCallback, useState } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { partial } from 'lodash';
import PropTypes from 'prop-types';

import Box from 'components/Box';

import { cxHelpers } from 'util/className';

import Benefit from './Benefit';
import BenefitForm from './BenefitForm';

const { cx } = cxHelpers('BenefitsList');

const BenefitsList = ({ updateUserInfo, user }) => {
  const [visibleFormIndex, setVisibleFormIndex] = useState(-1);
  const handleOnCancel = useCallback(() => {
    setVisibleFormIndex(-1);
  }, []);
  const handleOnSaveCompleted = handleOnCancel;
  const handleOnDeleteSuccess = handleOnCancel;
  const handleOnClickEdit = useCallback(index => {
    setVisibleFormIndex(index);
  }, []);
  return (
    <Box className={cx()}>
      {user
        .get('benefits')
        .map((benefit, i) =>
          visibleFormIndex === i ? (
            <BenefitForm
              key={`benefit-form-${i}`}
              benefit={benefit}
              onCancel={handleOnCancel}
              onSaveCompleted={handleOnSaveCompleted}
              onDeleteSuccess={handleOnDeleteSuccess}
              updateUserInfo={updateUserInfo}
              user={user}
              canDelete
            />
          ) : (
            <Benefit
              onClickEdit={partial(handleOnClickEdit, i)}
              benefit={benefit}
              key={`benefit-${i}`}
            />
          )
        )}
    </Box>
  );
};

BenefitsList.propTypes = {
  onSaveCompleted: PropTypes.func.isRequired,
  updateUserInfo: PropTypes.func.isRequired,
  user: ImmutablePropTypes.map.isRequired,
};

export default BenefitsList;
