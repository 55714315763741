import { TRACK_ADD_APPLICANTS_ACTIONS } from 'features/hiring/addApplicantManually/tracking_constants';
import { getUtmParams } from 'features/signUp/util/referralParams';

import { datadogLogInfo } from 'util/datadogLogs';
import { keysToSnakeCase } from 'util/object';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  LINKED_IN_EVENT_IDS,
  PRODUCT_AREAS,
  URL_STARTING_PATHS,
} from 'util/tracking_constants';

import amplitude from './trackingModules/amplitude';
import commandBar from './trackingModules/commandBar';
import createEventEndpoint from './trackingModules/createEventEndpoint';
import googleTagManager from './trackingModules/googleTagManager';
import { addCommonUxProperties } from './trackingModules/helpers';
import hotJar from './trackingModules/hotJar';

export const UX_TRACKING_DESTINATIONS = [
  amplitude,
  commandBar,
  hotJar,
  googleTagManager,
  createEventEndpoint,
];

const TEMP_SESSION = 'signUpSessionId';

// TODO: it is not clear if these are intended as event categories or product
// areas or something else entirely, but likely this will be removed.  These
// values are currently being assigned to attributes named `category` and
// `product_location` which is inconsistent with our schema today.
export const MISC_EVENT_VALUES = {
  hiring: 'Hiring',
  dashboard: 'dashboard',
};

export const EVENT_NAMES = {
  hiringManageApplicantsZeroState: 'applicant_tab_zero_state',
  terminateEmployeeSuccessModalViewed: 'viewed',
  terminateEmployeeSuccessModalClickedHireReplacement:
    'clicked_hire_a_replacement',
  terminateEmployeeSuccessModalClickedClose: 'clicked_close',
  terminateEmployeeSuccessModalClickedConfirmed: 'clicked_confirmed',
  createdJobRequest: 'New Job Request',
  repostedJobRequest: 'Reposted Job Request',
  repostedLocation: 'Reposted Location',
  downloadSignClicked: 'download_sign_clicked',
};

export const postEvent = ({ properties, ...other }) => {
  if (window.testEnv) {
    return;
  }

  const { eventAction } = other;

  const shouldFireDDLog = eventAction === EVENT_ACTIONS.OWNER_SIGN_UP;

  const updatedPayload = keysToSnakeCase({
    ...other,
    properties: addCommonUxProperties(properties),
    ...getUtmParams(),
    ...(window.Homebase.uxTrackingData || {}),
    shouldFireDDLog,
  });

  UX_TRACKING_DESTINATIONS.forEach(destination => {
    if (shouldFireDDLog) {
      datadogLogInfo({
        message: `${EVENT_ACTIONS.OWNER_SIGN_UP}-logs`,
        context: {
          scenario: 'before-sendEvent',
          ...updatedPayload,
        },
      });
    }

    destination.sendEvent(keysToSnakeCase(updatedPayload));
  });
};

export const trackEvent = (name, params, options = {}) =>
  window.Homebase.TrackingEvents.trackEvent(name, params, options);

export const identify = (userId, params, opts = {}) =>
  window.Homebase.TrackingEvents.identify(userId, params, opts);

export const amplitudeSetUserId = userId => {
  if (window.amplitude) window.amplitude.getInstance().setUserId(userId);
};

export const heapTrackEvent = (name, params = {}) => {
  if (window.heap) {
    window.heap.track(name, params);
  } else {
    document.addEventListener('heap-loaded', () => {
      window.heap.track(name, params);
    });
  }
};
export const heapIdentify = (userId, userProps = {}, eventProps = {}) => {
  if (userId && !isADemoUser(userProps.name, userProps.email)) {
    if (window.heap) {
      window.heap.identify(userId);
      heapAddUserProps(userProps);
      heapAddEventProps(eventProps);
    } else {
      document.addEventListener('heap-loaded', () => {
        window.heap.identify(userId);
        heapAddUserProps(userProps);
        heapAddEventProps(eventProps);
      });
    }
  }
};

export const isADemoUser = (name, email) =>
  name === 'Demo Account' && email.match(/homebase/);

export const heapAddUserProps = userProps => {
  if (userProps) {
    if (window.heap) {
      window.heap.addUserProperties(userProps);
    } else {
      document.addEventListener('heap-loaded', () => {
        window.heap.addUserProperties(userProps);
      });
    }
  }
};
export const heapAddEventProps = eventProps => {
  if (window.heap) {
    window.heap.addEventProperties(eventProps);
  } else {
    document.addEventListener('heap-loaded', () => {
      window.heap.addEventProperties(eventProps);
    });
  }
};
export const heapClearEventProps = () => {
  if (window.heap) {
    window.heap.clearEventProperties();
  } else {
    document.addEventListener('heap-loaded', () => {
      window.heap.clearEventProperties();
    });
  }
};

export const facebookTrackEvent = (eventType, params = {}) =>
  window.Homebase.TrackingEvents.facebookTrackEvent(eventType, params);

export const shareasaleTrackConversion = () =>
  window.Homebase.TrackingEvents.shareasaleTrackConversion();

export const accordantTrackConversion = () =>
  window.Homebase.TrackingEvents.accordantTrackConversion();

// Call heap identify with user and event props
export const initialHeapIdentify = analytics => {
  if (analytics && analytics.heapUserId && !analytics.skipIdentify) {
    const { heapUserId, heapUserProps, heapEventProps } = analytics;

    // Unset all event props to accommodate removing outdated tests
    heapClearEventProps();

    heapIdentify(heapUserId, heapUserProps, heapEventProps);
  }
};

// Hiring
export const logHiringEvent = params => {
  params.additional_data = params.additional_data || {};
  params.additional_data.current_path = window.location.pathname;

  window.Homebase.Hiring.Logger.log(params);
};

export const logHiringWidgetEvent = params =>
  logHiringEvent({
    product_location: 'hiring_widget_on_location_dashboard',
    ...params,
  });

export const trackGoogleConversionOwnerSignUp = () => {
  if (window.gtag) {
    window.gtag('event', 'conversion', {
      send_to: 'AW-943694906/qpZECJfcl5kBELrI_sED',
    });
  }
};

export const trackBingEvent = event => {
  window.uetq = window.uetq || [];
  window.uetq.push('event', event);
};

export const trackGoogleConversionCompanyCreation = company => {
  if (window.gtag) {
    window.gtag('event', 'conversion', {
      send_to: 'AW-943694906/Tw-PCMWijpkBELrI_sED',
      company_id: company.id,
    });
  }
};

export const trackLinkedInSignUp = () => {
  // https://joinhomebase.atlassian.net/browse/ON-806 is where the conversion_id  came from
  // eslint-disable-next-line no-unused-expressions
  window.lintrk &&
    window.lintrk('track', { conversion_id: LINKED_IN_EVENT_IDS.owner_signup });
};

// eventName - should match Event Class on the backend.
// e.g. settings_page - will use Events::SettingsPageEvent class on the backend
export const trackKinesisEvent = (
  eventName,
  eventType,
  actionType,
  properties = {}
) =>
  postEvent({
    event_name: eventName,
    eventType,
    actionType,
    properties,
  });

export const trackManagerCapEvent = (eventType, actionType, properties = {}) =>
  trackKinesisEvent('manager_cap', eventType, actionType, properties);

export const trackMultilocationTrialEvent = (
  eventType,
  actionType,
  properties = {}
) =>
  trackKinesisEvent('multilocation_trial', eventType, actionType, properties);

export const trackMonetizationCtaEventKinesis = (
  eventType,
  actionType,
  properties = {}
) =>
  trackKinesisEvent('monetization_cta', eventType, actionType, {
    ...properties,
    url: window.location.href,
  });

export const trackSignUpEvent = (eventType, properties = {}) => {
  const { event_action, event_category, action_type, ...props } = properties;

  postEvent({
    eventName: 'signup',
    productArea: PRODUCT_AREAS.SIGN_UP,
    properties: props,
    event_action,
    action_type,
    event_category,
    eventType,
  });
};

export const trackObSignUp = () => {
  if (window.obApi) {
    window.obApi('track', 'Sign Up');
  } else {
    document.addEventListener('obApi-loaded', () => {
      window.obApi('track', 'Sign Up');
    });
  }
};
export const trackObInitialActivation = () => {
  if (window.obApi) {
    window.obApi('track', 'Initial Activation');
  } else {
    document.addEventListener('obApi-loaded', () => {
      window.obApi('track', 'Initial Activation');
    });
  }
};

const checkRequiredArgs = (requiredArguments, logData) => {
  if (requiredArguments.some(arg => arg === undefined)) {
    if (logData && logData.logMessage)
      datadogLogInfo({
        message: `${logData.logMessage} ux-event-error`,
        context: {
          errorType: 'missing-required-parameter',
          ...(logData.context || {}),
        },
      });
    throw new Error('Missing required parameter');
  }

  if (requiredArguments.some(arg => typeof arg !== 'string')) {
    if (logData && logData.logMessage)
      datadogLogInfo({
        message: `${logData.logMessage} ux-event-error`,
        context: {
          errorType: 'value-not-string',
          ...(logData.context || {}),
        },
      });
    throw new Error('All required parameters must be strings');
  }
};

export const trackUxEvent = ({
  productArea,
  eventCategory,
  eventAction,
  actionType = null,
  properties = {},
} = {}) => {
  if (eventAction === EVENT_ACTIONS.OWNER_SIGN_UP) {
    datadogLogInfo({
      message: `${EVENT_ACTIONS.OWNER_SIGN_UP}-logs`,
      context: {
        scenario: 'trackUxEvent',
      },
    });
  }

  checkRequiredArgs([productArea, eventCategory, eventAction], {
    logMessage: eventAction,
    context: {
      eventName: 'ux',
      eventAction,
      productArea,
      eventCategory,
    },
  });

  if (eventAction === EVENT_ACTIONS.OWNER_SIGN_UP) {
    datadogLogInfo({
      message: `${EVENT_ACTIONS.OWNER_SIGN_UP}-logs`,
      context: {
        scenario: 'before-postEvent',
        event_name: 'ux',
        productArea,
        eventCategory,
        eventAction,
        actionType,
        properties,
      },
    });
  }

  postEvent({
    event_name: 'ux',
    productArea,
    eventCategory,
    eventAction,
    actionType,
    properties,
  });
};

export const initTimeDurationUxEvent = ({
  productArea,
  eventCategory,
  eventAction,
  key,
}) => {
  sessionStorage.setItem(
    `${productArea}-${eventCategory}-${eventAction}-${key}`,
    new Date()
  );
};

export const clearTimeDurationUxEvent = ({
  productArea,
  eventCategory,
  eventAction,
  key,
}) => {
  sessionStorage.removeItem(
    `${productArea}-${eventCategory}-${eventAction}-${key}`
  );
};

export const trackTimeDurationUxEvent = ({
  actionType,
  productArea,
  eventCategory,
  eventAction,
  key,
  properties = {},
}) => {
  const oldTime = sessionStorage.getItem(
    `${productArea}-${eventCategory}-${eventAction}-${key}`
  );
  if (!oldTime) {
    return;
  }

  clearTimeDurationUxEvent({ productArea, eventCategory, eventAction, key });

  const newTime = new Date();

  properties.duration = (newTime - new Date(oldTime)) / 1000; // seconds

  trackUxEvent({
    actionType,
    productArea,
    eventCategory,
    eventAction,
    properties,
  });
};

// The originContext item in sessionStorage will be removed upon load of a
// any page if the context is more than 10 seconds old (stale).
// This happens in this script: app/views/layouts/scripts/_remove_origin_context.html
export const setOriginContext = trackingInfo => {
  const originContext = {
    created: new Date(),
    trackingInfo,
  };
  sessionStorage.setItem('originContext', JSON.stringify(originContext));
};

export const setOriginContextByUrl = url => {
  /* genericUrl allows us to use a dynamic url as a key in URL_STARTING_PATHS */
  const genericUrl = url.replace(/[0-9]+/g, ':id');
  if (Object.keys(URL_STARTING_PATHS).includes(genericUrl)) {
    setOriginContext({ starting_path: URL_STARTING_PATHS[genericUrl] });
  }
};

export const getOriginContext = () =>
  JSON.parse(sessionStorage.getItem('originContext'));

export const getOriginContextTrackingInfo = () => {
  if (!getOriginContext()) {
    return null;
  }
  return getOriginContext().trackingInfo;
};

export const getOriginContextStartingPath = () =>
  getOriginContextTrackingInfo()
    ? getOriginContextTrackingInfo().starting_path
    : null;

export const trackGrowthEvent = ({
  productArea,
  eventCategory,
  eventAction,
  properties = {},
  experimentName,
} = {}) => {
  checkRequiredArgs([productArea, eventCategory, eventAction, experimentName]);

  postEvent({
    event_name: 'growth',
    productArea,
    eventCategory,
    eventAction,
    properties,
    experimentName,
  });
};

export const trackAnnouncementsEvent = ({
  announcementType,
  eventAction,
  properties = {},
}) => {
  postEvent({
    event_name: 'announcement',
    announcementType,
    eventAction,
    properties,
  });
};

export const trackTeamAnnouncementsEvent = (eventAction, properties = {}) =>
  trackUxEvent({
    productArea: PRODUCT_AREAS.TEAM_ANNOUNCEMENTS,
    eventCategory: EVENT_CATEGORIES.TEAM_ANNOUNCEMENTS_CARD,
    eventAction,
    properties,
  });

export const trackAddApplicantsManuallyEvent = (productArea, properties = {}) =>
  trackUxEvent({
    productArea,
    eventCategory: EVENT_CATEGORIES.ADD_APPLICANTS_MANUALLY,
    eventAction: TRACK_ADD_APPLICANTS_ACTIONS.CLICKED_ADD_APPLICANT,
    properties,
  });

export const trackArchiveApplicantsEvent = (eventAction, properties = {}) =>
  trackUxEvent({
    productArea: PRODUCT_AREAS.HIRING_DASHBOARD,
    eventCategory: EVENT_CATEGORIES.AUTO_ARCHIVE_MODAL,
    eventAction,
    properties,
  });

export const trackPartnerIntegationEvent = ({
  eventCategory,
  eventAction,
  partnerName,
} = {}) => {
  checkRequiredArgs([eventCategory, eventAction, partnerName]);

  postEvent({
    event_name: 'partner_integration',
    productArea: PRODUCT_AREAS.INTEGRATION,
    eventCategory,
    eventAction,
    partnerName,
  });
};

export const trackHrProEvent = (eventCategory, eventAction, properties = {}) =>
  trackUxEvent({
    productArea: PRODUCT_AREAS.HR_PRO,
    eventCategory,
    eventAction,
    properties,
  });

export const trackUserWebTimeclockEvent = (
  eventCategory,
  eventAction,
  properties = {}
) =>
  trackUxEvent({
    productArea: PRODUCT_AREAS.USER_WEB_TIMECLOCK,
    eventCategory,
    eventAction,
    properties,
  });

export const generateTemporarySessionId = () =>
  Math.random().toString(36).substring(2);

export const generateAndSetTemporarySessionId = () => {
  const temporarySessionId = generateTemporarySessionId();
  sessionStorage.setItem(TEMP_SESSION, temporarySessionId);
  return temporarySessionId;
};

export const getTemporarySessionId = () =>
  sessionStorage.getItem(TEMP_SESSION) || generateAndSetTemporarySessionId();
