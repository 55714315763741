import React from 'react';
import { connect } from 'react-redux';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import CircularInfinite from 'fe-design-base/molecules/Loaders/CircularInfinite';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { getCompanyRolesArray } from 'selectors/addTeam';
import { getPacketProps } from 'selectors/drawers';

import BenefitsWidget from 'features/employeeProfile/JobDetailsView/BenefitsWidget';
import PostTaxDeductionsWidget from 'features/employeeProfile/JobDetailsView/PostTaxDeductionsWidget/PostTaxDeductionsWidget';
import RecurringEarningsWidget from 'features/employeeProfile/JobDetailsView/RecurringEarningsWidget';
import { I18N_TITLE_PATH } from 'features/team/components/AddEmployeeForm/constants';
import { closeAddNewRoleModal, openToast } from 'features/team/slice';

import { toI18n } from 'util/i18n';

const StepTwo = ({ updateUserInfo, user, showFormWithSteps }) => {
  if (isEmpty(user)) {
    return <CircularInfinite size="large" variant="dark" />;
  }

  const boxStyle = showFormWithSteps
    ? {
        bgcolor: 'mono0',
        bradiusm: true,
        bcolor: 'mono300',
        bw: 1,
        p: 20,
      }
    : {};

  return (
    <Box {...boxStyle}>
      <Box mb={24}>
        <Box mb={12}>
          <Text color="mono900" variant="heading3">
            {toI18n(`${I18N_TITLE_PATH}.benefits_and_deductions.title`)}
          </Text>
        </Box>
        <Text color="mono700" variant="bodySm">
          {toI18n(`${I18N_TITLE_PATH}.benefits_and_deductions.subtitle`, {
            props: { teamMemberFirstName: user.get('first_name') },
          })}
        </Text>
      </Box>
      <BenefitsWidget
        user={user}
        updateUserInfo={updateUserInfo}
        showFormWithSteps={showFormWithSteps}
      />
      <PostTaxDeductionsWidget
        user={user}
        updateUserInfo={updateUserInfo}
        showFormWithSteps={showFormWithSteps}
      />
      <RecurringEarningsWidget
        user={user}
        updateUserInfo={updateUserInfo}
        showFormWithSteps={showFormWithSteps}
      />
    </Box>
  );
};

StepTwo.propTypes = {
  companyRolesArray: PropTypes.object,
  contactInfoRequired: PropTypes.bool.isRequired,
  formFilloutRecommended: PropTypes.bool.isRequired,
  isAIOLocation: PropTypes.bool.isRequired,
  isPayrollEnrolled: PropTypes.bool.isRequired,
  onCloseAddNewRoleModal: PropTypes.func,
  onOpenToast: PropTypes.func,
  onResetContactInfoRequired: PropTypes.func.isRequired,
  onSetHasEmailError: PropTypes.func.isRequired,
  onSetHasPhoneError: PropTypes.func.isRequired,
  onSetIsAdding: PropTypes.func.isRequired,
  onSetIsCheckingEmail: PropTypes.func.isRequired,
  onSetIsCheckingPhone: PropTypes.func.isRequired,
  onSetRehireId: PropTypes.func.isRequired,
  onSetRehireLevel: PropTypes.func.isRequired,
  rehireLevel: PropTypes.string,
};

export default connect(
  state => ({
    companyRolesArray: getCompanyRolesArray(state),
    packetProps: getPacketProps(state),
  }),
  {
    onCloseAddNewRoleModal: closeAddNewRoleModal,
    onOpenToast: openToast,
  }
)(StepTwo);
