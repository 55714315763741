/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import Box from 'fe-design-base/atoms/Box';
import IconButton from 'fe-design-base/molecules/IconButton';

import { getPayPeriod } from 'selectors/payroll';

import Drawer from 'components/Drawer';

import { cxHelpers } from 'util/className';

import { publishOnConfirmAndPay } from './events';
import { closeStripeCheckoutDrawer as getCloseStripeCheckoutDrawer } from './slice';
import * as views from './views';

const { cx } = cxHelpers('StripeCheckoutDrawer');
export interface StripeCheckoutDrawerProps {
  closeStripeCheckoutDrawer: () => any;
  initialViewKey?: 'Success' | 'Checkout';
  payPeriod: string;
}

export const StripeCheckoutDrawer: React.FC<StripeCheckoutDrawerProps> = ({
  closeStripeCheckoutDrawer,
  initialViewKey,
  payPeriod,
}) => {
  const [currentViewKey, setCurrentViewKey] = useState(
    initialViewKey || 'Checkout'
  );
  const [isLoading, setIsLoading] = useState(false);
  const CurrentView = views[currentViewKey as keyof typeof views];

  const handleOnLoading = useCallback((loading: boolean) => {
    setIsLoading(loading);
  }, []);

  const handleOnSuccess = useCallback(() => {
    setCurrentViewKey('Success');
  }, []);

  const handleOnConfirmAndPay = useCallback(() => {
    publishOnConfirmAndPay();
  }, []);
  return (
    <Drawer className={cx()} maxWidth="464px" open>
      <Box hright mt={16} mr={16}>
        <IconButton
          onClick={closeStripeCheckoutDrawer}
          icon="Close"
          size="small"
          uxElement="checkout_drawer.close_button"
        />
      </Box>
      <Box mt={12} ph={32}>
        <CurrentView
          onSuccess={handleOnSuccess}
          onConfirmAndPay={handleOnConfirmAndPay}
          onLoading={handleOnLoading}
          isLoading={isLoading}
          selectedPayPeriod={payPeriod}
          closeDrawer={closeStripeCheckoutDrawer}
        />
      </Box>
    </Drawer>
  );
};
export default connect(state => ({ payPeriod: getPayPeriod(state) }), {
  closeStripeCheckoutDrawer: getCloseStripeCheckoutDrawer,
})(StripeCheckoutDrawer);
