import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import FDBDatePickerField from 'fe-design-base/organisms/FDBDatePickerField';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import { getUSStateAbbrOptions } from 'selectors/session';

import Box from 'components/Box';
import Text from 'components/Text';

import { cxHelpers } from 'util/className';
import { datePickerFormat, formatDate } from 'util/dateTime';
import { toI18n } from 'util/i18n';

import FieldRenderer from '../../FieldRenderer';
import PercentToolTip from '../BenefitsWidget/PercentToolTip';

const { cx, cxEl } = cxHelpers('ChildSupportFormFieldRows');

const ChildSupportFormFieldRows = ({ showFormWithSteps, children }) => {
  const { setFieldValue, values } = useFormikContext();

  const stateAbbrOptions = useSelector(getUSStateAbbrOptions);
  const handleIssueDateBlur = useCallback(() => {
    setFieldValue('issue_date', formatDate(values.issue_date, 'parsable_date'));
  }, [setFieldValue, values.issue_date]);
  return (
    <Box className={cx()} pb20 mb20>
      <Box row mb32>
        <Box grow basis="25%">
          <Box mb12>
            <Text
              fs={showFormWithSteps ? 14 : 16}
              fw600
              lh={1.25}
              navy
              i18n="team.employee_profile.tax_deductions.external_id_label"
            />
          </Box>
          <Box w={175}>
            <FieldRenderer editMode name="external_id" component="TextInput" />
          </Box>
        </Box>
        <Box grow basis="15%">
          <Box mb12>
            <Text
              fs={showFormWithSteps ? 14 : 16}
              fw600
              lh={1.25}
              navy
              i18n="team.employee_profile.tax_deductions.state_issued_label"
            />
          </Box>
          <Box w={100}>
            <FieldRenderer
              editMode
              name="state_issued"
              component="SelectInput"
              options={stateAbbrOptions}
              hideClear
            />
          </Box>
        </Box>
        <Box grow basis="60%">
          <Box mb12>
            <Text
              fs={showFormWithSteps ? 14 : 16}
              fw600
              lh={1.25}
              navy
              i18n="team.employee_profile.tax_deductions.issue_date_label"
            />
          </Box>
          <Box className={cxEl('issue-date')} maxw={220}>
            <FDBDatePickerField
              hideClearIcon
              name="issue_date"
              value={
                values.issue_date ? datePickerFormat(values.issue_date) : null
              }
              onBlur={handleIssueDateBlur}
            />
          </Box>
        </Box>
      </Box>
      <Box row mb32>
        <Box grow basis="40%">
          <Box mb12>
            <Text
              fs={showFormWithSteps ? 14 : 16}
              fw600
              lh={1.25}
              navy
              i18n="team.employee_profile.tax_deductions.fixed_amount_label"
            />
          </Box>
          <Box row>
            <FieldRenderer
              editMode
              name="amount"
              component="TextInput"
              placeholder="$"
              type="currency"
            />
            <Box vtop={showFormWithSteps} vcenter={!showFormWithSteps} ml12>
              <Text
                fs={showFormWithSteps ? 14 : 16}
                fw600
                lh={1.25}
                navy
                i18n="team.employee_profile.tax_deductions.per_pay_period_label"
              />
            </Box>
          </Box>
        </Box>

        <Box grow basis="30%">
          <Box mb12>
            <PercentToolTip
              content={toI18n(
                'team.employee_profile.tax_deductions.percent_tooltip_content'
              )}
              i18n="team.employee_profile.tax_deductions.max_percent_label"
              showFormWithSteps={showFormWithSteps}
            />
          </Box>
          <Box w={100}>
            <FieldRenderer
              editMode
              name="maximum_percent"
              component="TextInput"
              placeholder="0.0%"
              type="percent"
            />
          </Box>
        </Box>

        <Box vbottom hright grow basis="30%">
          {children}
        </Box>
      </Box>
    </Box>
  );
};

ChildSupportFormFieldRows.propTypes = {
  values: PropTypes.object,
  isEditing: PropTypes.bool,
};

export default ChildSupportFormFieldRows;
