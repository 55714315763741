const DEFAULT_ROUTE = '/clover/sign-up';

export const ROUTES = {
  LOADING: `${DEFAULT_ROUTE}/loading`,
  BUSINESS_DETAILS: `${DEFAULT_ROUTE}/business-details`,
  TEAM: `${DEFAULT_ROUTE}/team`,
  INVITE_TEAM: `${DEFAULT_ROUTE}/invite-team`,
  PRIMER: `${DEFAULT_ROUTE}/primer`,
};

export const SLICE_NAME = 'cloverSignUp';

export const ENDPOINTS = {
  CREATE_CLOVER_ACCOUNT: '/clover/sign-up/create',
  UPDATE_COMPANY_PROPERTIES: '/onboarding/company_onboarding_properties',
  SYNC_CLOVER_EMPLOYEES: '/employees/sync',
  FETCH_EMPLOYEES: '/employees.json',
  INVITE_EMPLOYEES: '/api/v5/users/invite_all',
  SEND_INSTRUCTIONS: '/clover/sign-up/send_instructions',
};
