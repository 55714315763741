import React, { useCallback, useEffect, useState } from 'react';
import NewBox from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import Alert from 'fe-design-base/molecules/Alert';
import Button from 'fe-design-base/molecules/Button';
import { Form, Formik } from 'formik';
import { partial } from 'lodash';
import PropTypes from 'prop-types';

import Box from 'components/Box';
import TooltipWrapper from 'components/TooltipWrapper';

import * as flashNotice from 'util/flashNotice';
import { toI18n } from 'util/i18n';

const EditableSection = ({
  titleI18n,
  titleI18nProps,
  alertI18n,
  alertVariant,
  className,
  onSave,
  onSaveCompleted,
  onCancel,
  updateUserInfo,
  onValidate,
  initialValues,
  cantEdit,
  editButtonDisabled,
  editButtonTop,
  hideEditButton,
  editButtonContent,
  children,
  cxEl,
  isSaveButtonDisabled = false,
  initEditMode = false,
  initialTouched = {},
  initialErrors = {},
  onError,
  noBorder = false,
  noPadding = false,
  showCancelButton = true,
  saveButtonProps,
  validationSchema,
}) => {
  const [editMode, setEditMode] = useState(!!initEditMode);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (initEditMode) {
      setEditMode(initEditMode);
    }
  }, [initEditMode]);

  function afterSaveCallback(response) {
    updateUserInfo(response);
    setIsSubmitting(false);
    setEditMode(false);
    if (onSaveCompleted) {
      onSaveCompleted();
    }
  }

  function afterErrorCallback(error) {
    setIsSubmitting(false);
    if (onError) return onError(error);
    flashNotice.error(toI18n('errors.generic'));
  }

  const handleSave = useCallback((values, formikBag) => {
    setIsSubmitting(true);
    const promise = onSave(values, formikBag);
    if (promise) {
      promise.then(afterSaveCallback).catch(afterErrorCallback);
    } else {
      setIsSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleOnClickCancel(resetForm) {
    if (onCancel) {
      onCancel();
    }

    resetForm();
    setEditMode(false);
  }

  return (
    <Formik
      enableReinitialize
      onSubmit={handleSave}
      initialValues={initialValues}
      validate={onValidate}
      initialTouched={initialTouched}
      initialErrors={initialErrors}
      validationSchema={validationSchema}
    >
      {({ values, resetForm, ...formProps }) => (
        <Form>
          <Box className={className}>
            {titleI18n && (
              <Box mb={8}>
                <Text
                  variant="heading2"
                  i18n={titleI18n}
                  i18nProps={titleI18nProps}
                />
              </Box>
            )}
            {alertI18n && (
              <Box mt={20} mb={20}>
                <Alert variant={alertVariant}>
                  <NewBox>
                    <Text variant="body" color="mono900">
                      {toI18n(alertI18n)}
                    </Text>
                  </NewBox>
                </Alert>
              </Box>
            )}

            <Box
              b={!noBorder}
              bradius={3}
              relative
              p={noPadding ? 0 : 24}
              overflowX="visible"
            >
              {!hideEditButton &&
                (editButtonDisabled || !cantEdit) &&
                !editMode && (
                  <Box
                    absolute
                    top={editButtonTop || 24}
                    right={24}
                    className={cxEl ? cxEl('edit') : null}
                  >
                    <TooltipWrapper
                      enabled={editButtonDisabled}
                      content={editButtonContent || ''}
                      trigger="hover"
                      preferPlace="left"
                      theme="black"
                    >
                      <Button
                        variant="secondary"
                        startIcon="Edit"
                        disabled={editButtonDisabled}
                        onClick={partial(setEditMode, true)}
                      >
                        <Text i18n="actions.edit" />
                      </Button>
                    </TooltipWrapper>
                  </Box>
                )}

              {children({
                editMode,
                values,
                afterSaveCallback,
                afterErrorCallback,
                ...formProps,
              })}

              {editMode && (
                <Box rowreverse mt32>
                  <Box row>
                    {showCancelButton && (
                      <Box mr12>
                        <Button
                          variant="tertiary"
                          onClick={partial(handleOnClickCancel, resetForm)}
                        >
                          <Text i18n="actions.cancel" />
                        </Button>
                      </Box>
                    )}
                    <Box>
                      <Button
                        variant="secondary"
                        disabled={
                          !formProps.isValid ||
                          !formProps.dirty ||
                          isSaveButtonDisabled
                        }
                        isLoading={isSubmitting}
                        type="submit"
                        {...saveButtonProps}
                      >
                        <Text i18n="actions.save" />
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

EditableSection.propTypes = {
  titleI18n: PropTypes.string,
  titleI18nProps: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  cantEdit: PropTypes.bool,
  editButtonDisabled: PropTypes.bool,
  editButtonTop: PropTypes.bool,
  hideEditButton: PropTypes.bool,
  editButtonContent: PropTypes.string,
  primaryButtonTheme: PropTypes.string,
  cxEl: PropTypes.func,
  initialTouched: PropTypes.object,
  isSaveButtonDisabled: PropTypes.bool,
  onSaveCompleted: PropTypes.func,
  onError: PropTypes.func,
  noBorder: PropTypes.bool,
  noPadding: PropTypes.bool,
  showCancelButton: PropTypes.bool,
  saveButtonProps: PropTypes.object,
};

export default EditableSection;
